import React, { useEffect, useState, useContext } from "react";
import { EnvContext } from "../context/EnvContext";

const EnvironmentStatus = ({}) => {
  // environment
  const environment = useContext(EnvContext);

  return (
    <>
      {environment == "staging" ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#f7ff00",
            top: 0,
            height: 20,
            left: 0,
            width: 20,
            borderRadius: 0,
            borderBottomRightRadius: 15,
            animation: "pulse 1.5s infinite",
            fontSize: 12,
            fontWeight: 600,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            // shadow red
            boxShadow: "0px 0px 6px rgba(255,0,0,.8)",
          }}
        >
          S
          <style>
            {`
      @keyframes pulse {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
      }
    `}
          </style>
        </div>
      ) : null}
    </>
  );
};

export default EnvironmentStatus;
