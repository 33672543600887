import { doc, getDoc, increment, collection, query, where, getDocs, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { currentProductPrice } from "./currentProductPrice";
import createAnonymousUser from "./createAnonymousUser";
import config from "../config";
import { logAnalyticsEvent } from "../firebase/firebaseConfig";
import axios from "axios";

const base62 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

const numberToBase62 = (num, length) => {
  let result = "";
  while (result.length < length) {
    result = base62[num % 36] + result;
    num = Math.floor(num / 36);
  }
  return result;
};

// Pass firestore instance as an argument
const addToCart = async (product_id, userSettings, firestore, quantity = 1, collectionPath) => {
  try {
    // Check if userSettings contains a valid user or create an anonymous user if necessary
    let uid;
    if (userSettings && userSettings.uid) {
      // If userSettings has a valid user, use its UID
      uid = userSettings.uid;
    } else {
      // Create an anonymous user and get its UID
      const user = await createAnonymousUser();
      uid = user.uid;
    }

    // Fetch data from Firestore using doc and getDoc
    const docRefPath = `${collectionPath}Listings/ListingsList/DataBase/${product_id}`;
    const docRef = doc(firestore, docRefPath);
    const docSnap = await getDoc(docRef);
    const productData = docSnap.data();

    if (!productData) {
      console.log("No such document!");
      window.alert("Product not found!");
      return;
    }

    // Check listing stock amount
    if (productData.listing_stock_amount === 0) {
      console.log("Product not available!");
      window.alert("Product not available!");
      return;
    }

    // Determine if the product is on sale
    productData.finalPrice = await currentProductPrice(productData);
    const cartRefPath = `${collectionPath}Carts/CartsList/DataBase`;
    const cartRef = query(collection(firestore, cartRefPath), where("user_id", "==", uid), where("status", "==", "ordering"));
    const cartSnap = await getDocs(cartRef);
    let cartID;

    // Data to remove from productData
    const addToCartData = {
      brand_name: productData.brand_name,
      brand_uid: productData.brand_uid,
      // final price
      final_price: parseFloat(parseFloat(productData.finalPrice?.finalPrice).toFixed(2)),

      // listing price
      listing_price: productData?.listing_price,
      // sale
      listing_sale_endDate: productData?.listing_sale_endDate,
      listing_sale_price: productData?.listing_sale_price,
      listing_sale_endDate: productData?.listing_sale_endDate,
      // sooperdeal
      sooperdeal_expiration_date: productData?.sooperdeal_expiration_date,
      sooperdeal_price: productData?.sooperdeal_price,
      sooperdeal_start_date: productData?.sooperdeal_start_date,
      //
      product_id: productData.id,
      product_name: productData?.product_name,
      images: productData?.images,
      product_uid: productData.product_uid,
      seller_id: productData.seller_id,
      seller_name: productData.seller_name,
      seller_product_id: productData.seller_product_id,
    };

    // Analytics
    logAnalyticsEvent("add_to_cart", {
      content_type: "add_to_cart_button",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: collectionPath !== "" ? "production" : "staging",
      product_id: productData.id,
      product_name: productData.product_name,
      product_uid: productData.product_uid,
      seller_id: productData.seller_id,
      seller_name: productData.seller_name,
      seller_product_id: productData.seller_product_id,
    });

    // Assign price to addToCartData
    addToCartData.price = await currentProductPrice(productData);

    const ensureValues = (data) => {
      return Object.keys(data).reduce((acc, key) => {
        acc[key] = data[key] !== undefined ? data[key] : null;
        return acc;
      }, {});
    };

    if (cartSnap.empty) {
      // Create a new cart
      const newCartRef = doc(collection(firestore, cartRefPath));
      cartID = newCartRef.id;
      await setDoc(newCartRef, {
        user_id: uid,
        status: "ordering",
        date: new Date(),
        cart_sooperstock_ID: numberToBase62(Math.floor(Math.random() * 1000000000), 6),
      });

      // Ensure all undefined values in addToCartData are converted to null
      const cartDataWithDefaults = ensureValues(addToCartData);

      // Add item to the new cart
      await setDoc(doc(firestore, `${cartRefPath}/${cartID}/Items`, product_id), {
        ...cartDataWithDefaults,
        quantity,
      });
    } else {
      // Update existing cart
      cartID = cartSnap.docs[0].id;
      const itemRef = doc(firestore, `${cartRefPath}/${cartID}/Items`, product_id);
      const itemSnap = await getDoc(itemRef);
      const currentItemQuantity = itemSnap.exists() ? itemSnap.data().quantity : 0;

      // Handle SooperDeal limit
      const maxAllowedQuantity =
        productData.finalPrice?.type === "sooperdeal" ? productData.sooperdeal_limit_per_order || Infinity : productData.listing_limit_per_order || Infinity;
      const totalQuantity = currentItemQuantity + quantity;

      if (totalQuantity > maxAllowedQuantity) {
        window.alert(`You can only add a maximum of ${maxAllowedQuantity} units of this item.`);
        return;
      }

      // Ensure all undefined values in addToCartData are converted to null
      const cartDataWithDefaults = ensureValues(addToCartData);

      if (itemSnap.exists()) {
        if (totalQuantity === 0) {
          // Delete the item from the cart if the quantity is zero
          await deleteDoc(itemRef);
        } else {
          await updateDoc(itemRef, {
            quantity: increment(quantity),
          });
        }
      } else if (quantity > 0) {
        // Only add a new item if quantity is greater than zero
        await setDoc(itemRef, {
          ...cartDataWithDefaults,
          quantity,
        });
      }
    }

    // Update the total number of items in the cart
    const cartItemsSnap = await getDocs(collection(firestore, `${cartRefPath}/${cartID}/Items`));
    let totalItems = 0;
    cartItemsSnap.forEach((item) => {
      totalItems += item.data().quantity;
    });

    // Call Klaviyo API for cart tracking
    if (userSettings?.email) {
      const payload = {
        email: userSettings?.email,
        cartId: cartID,
        cartItems: cartItemsSnap.docs.map((item) => {
          return {
            product_id: item.data().product_id,
            product_image:
              // if there is images in the product data, use the first image, else use an empty string
              item.data().images ? item.data().images[0] : "",
            product_name: item.data().product_name,
            quantity: item.data().quantity,
            price: item.data().final_price,
          };
        }, []),
        cartValue: 35,
        timestamp: new Date().toISOString(),
      };
      try {
        const klaviyoResponse = await axios.post(`${config.apiConfig.API_ENDPOINT_URL}/helper/klaviyoCartActivity`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Klaviyo response:", klaviyoResponse.data);
      } catch (error) {
        console.error("Error sending data to Klaviyo:", error.message);
      }
    }

    // You can handle totalItems here as needed for your web app
    console.log(`Total items in cart: ${totalItems}`);
  } catch (error) {
    console.error("Error adding to cart: ", error);
    // Handle error appropriately for your web app
  }
};

export default addToCart;
