import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/global.css";
import { EnvContext } from "../context/EnvContext";
import { text } from "@fortawesome/fontawesome-svg-core";
import { fontSize, width } from "@mui/system";

const Footer = () => {
  const environment = useContext(EnvContext);
  const navigate = useNavigate();

  return (
    <div style={styles.footerContainer}>
      {/* Back to Top Button */}
      <button style={{ ...styles.backToTopButton, display: "none" }} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        Back to Top <span style={styles.backToTopIcon}>▲</span>
      </button>
      <div style={styles.backToTopContainer}></div>

      {/* Footer Content */}
      <div style={styles.footerContent}>
        <div style={{ ...styles.logo.container, paddingBottom: 20 }} onClick={() => navigate("/")}>
          <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "auto", maxHeight: 35, width: "100%" }} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={styles.content.container}>
            <p onClick={() => navigate("/legal")} style={{ ...styles.content.p, cursor: "pointer" }}>
              Terms & Conditions
            </p>
            <p onClick={() => navigate("/legal")} style={{ ...styles.content.p, cursor: "pointer" }}>
              Privacy Policy
            </p>
            <p onClick={() => navigate("/faq")} style={{ ...styles.content.p, cursor: "pointer" }}>
              FAQ
            </p>
          </div>
          <div style={{ ...styles.content.container, justifyContent: "flex-end" }}>
            <p style={{ ...styles.content.p, textAlign: "right" }}>© 2025 SooperStock, Inc.</p>
            <p style={{ ...styles.content.p, textAlign: "right" }}>All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  footerContainer: {
    position: "relative",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 50,
  },
  backToTopContainer: {
    marginBottom: "20px",
  },
  logo: {
    container: {
      flex: 2.5,
      cursor: "pointer",
      display: "flex",
    },
    text: {
      fontSize: "18px",
    },
  },
  content: {
    container: {
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "flex-start",
    },
    p: {
      textAlign: "left",
      paddingBottom: 5,
      fontSize: 12,
    },
  },
  backToTopButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid black",
    borderRadius: "20px",
    backgroundColor: "white",
    color: "black",
    fontSize: "16px",
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
  },
  backToTopIcon: {
    marginLeft: "5px",
  },
  footerContent: {
    backgroundColor: "black",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    padding: 20,
  },
  logoContainer: {
    marginBottom: "20px",
  },
  logoText: {
    fontSize: "24px",
    fontWeight: "bold",
    backgroundColor: "red",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  linksContainer: {
    marginBottom: "20px",
  },
  linkText: {
    margin: "5px 0",
    fontSize: "14px",
    cursor: "pointer",
  },
  copyRightContainer: {
    textAlign: "center",
  },
  copyRightText: {
    fontSize: "12px",
    margin: "3px 0",
    opacity: 0.8,
  },
};

export default Footer;
