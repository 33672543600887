import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { UserContext } from "../../context/UserContext";
import { EnvContext } from "../../context/EnvContext";
import { collection, doc, getDoc, query, where, getDocs, updateDoc, addDoc, deleteDoc, writeBatch } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../../elements/Header";
import config from "../../config";
import AddressCheckout from "../../elements/AddressCheckout";
import axios from "axios";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";
import Footer from "../../elements/Footer";

// Constants
const redirectURL = window.location.origin;

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const db = useFirestore();
  const firestore = useFirestore();
  const { user, loading } = React.useContext(UserContext);
  const environment = useContext(EnvContext);

  const [addresses, setAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Stripe");
  const [appliedDiscount, setAppliedDiscount] = useState({
    discount: 0,
    discountAmount: 0,
    name: "",
    percentage: 0,
  });

  const [walletBalance, setWalletBalance] = useState(0);
  const [minPaymentAmount, setMinPaymentAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const [checkoutData, setCheckoutData] = useState({
    cartID: null,
    cartSooperstockID: null,
    items: [],
    subtotal: 0,
    shippingCost: 0,
    freeShippingThreshold: 0,
    appliedDiscount: {
      discountAmount: 0,
      percentage: 0,
      name: "",
    },
    walletBalance: 0,
    minPaymentAmount: 0,
    finalAmount: 0,
  });

  ///
  const forceStagingPayment = false;

  const isStripeStaging = environment === "staging";
  const stripePublicKey =
    isStripeStaging || forceStagingPayment
      ? "pk_test_51Q7NV6BmPhwnfvGwfu86VnL4v1Hk1ERn0nT7VgJbUpsZcrysBau6hiOdE187kwVtikRtidfIK3l3JM2JEjxriSy000QMRtluU5"
      : "pk_live_51Q7NV6BmPhwnfvGw5LHXyRuz5pnpQcOU3UEgx5WFI2UiMONqNq461OPuufnhgyqtgs8sDusOzQPCUJKVGiaBCXgg00SQHwT9Hg";

  //
  const stripePromise = loadStripe(stripePublicKey);
  const {
    cartID = null,
    cart_sooperstock_ID = null,
    items = [],
    subtotal = 0,
    shippingCost = 0,
    freeShipingThreshold = 0,
    finalAmount = 0,
  } = location.state || {};

  const collectionPath = isStripeStaging ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const validSubtotal = subtotal || 0;
  const validShippingCost = shippingCost || 0;

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true); // Set loading to true before fetching data
        await fetchCheckoutData(); // Wait for fetchCheckoutData to complete
        await fetchWalletData(); // Wait for fetchWalletData to complete
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after all tasks are done
      }
    };

    loadData(); // Invoke the asynchronous function

    // Cleanup function (if needed)
    return () => {
      // Any cleanup logic, if necessary
    };
  }, [user, location.state?.cartID, firestore, collectionPath]);

  //
  //
  // GET ALL DATA

  const fetchCheckoutData = async () => {
    try {
      if (!user || !location.state?.cartID) return;

      const cartID = location.state.cartID;

      // Fetch cart details
      const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartID);
      const cartSnapshot = await getDoc(cartDocRef);
      const cartData = cartSnapshot.exists() ? cartSnapshot.data() : {};
      await updateDoc(cartDocRef, {
        opening_checkout_page: new Date(), // Firebase server timestamp
      });

      // Fetch wallet balance
      const walletCollection = collection(firestore, `${collectionPath}Wallets/UserWallets/DataBase`);
      const walletQuery = query(walletCollection, where("user_uid", "==", user.uid));
      const walletSnapshot = await getDocs(walletQuery);
      const totalWalletBalance = walletSnapshot.docs.reduce((acc, doc) => acc + (doc.data().wallet_value || 0), 0);

      // Fetch minimum wallet payment amount
      const settingsDocRef = doc(firestore, `${collectionPath}SooperstockSettings`, "Wallet");
      const settingsDoc = await getDoc(settingsDocRef);
      const minWalletPayment = settingsDoc.exists() ? settingsDoc.data().minWalletPayment / 100 : 0;

      // Fetch shipping settings
      const shipmentDocRef = doc(firestore, `${collectionPath}SooperstockSettings`, "Shipment");
      const shipmentDoc = await getDoc(shipmentDocRef);
      const shipmentData = shipmentDoc.exists() ? shipmentDoc.data() : {};

      //

      // Update state
      setCheckoutData({
        cartID,
        cartSooperstockID: cartData.cart_sooperstock_ID || null,
        items: cartData.items || [],
        subtotal: cartData.subtotal || 0,
        shippingCost: cartData.shippingCost || 0,
        freeShippingThreshold: shipmentData.freeShipmentThreshold || 0,
        appliedDiscount: {
          discountAmount: cartData.applied_discount_amount || 0,
          percentage: cartData.applied_discount_percentage || 0,
          name: cartData.applied_discount_name || "",
        },
        walletBalance: totalWalletBalance,
        minPaymentAmount: minWalletPayment,
        finalAmount: cartData.finalAmount || 0,
      });

      // Analytics
      logAnalyticsEvent("checkout_view", {
        content_type: "checkout_page",
        page_title: environment == "staging" ? "STAGING - Checkout Page" : "Checkout Page",
        page_location: window.location.href,
        page_path: window.location.pathname,
        environment: environment,
        cart_id: cartID,
        cart_items: cartData.items?.length,
        cart_subtotal: cartData.subtotal,
        cart_shipping: cartData.shippingCost,
        cart_discount: cartData.applied_discount_amount,
        cart_wallet_balance: totalWalletBalance,
        cart_total: cartData.finalAmount,
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching checkout data:", error);
      setIsLoading(false);
    }
  };

  //
  //
  // WALLET PAYMENT

  const fetchWalletData = async () => {
    try {
      if (!user) return;

      // Fetch wallet balance
      const walletCollection = collection(firestore, `${collectionPath}Wallets/UserWallets/DataBase`);
      const walletQuery = query(walletCollection, where("user_uid", "==", user.uid));
      const walletSnapshot = await getDocs(walletQuery);
      const totalBalance = walletSnapshot.docs.reduce((acc, doc) => acc + (doc.data().wallet_value || 0), 0);

      // Fetch minimum payment threshold
      const settingsDocRef = doc(firestore, `${collectionPath}SooperstockSettings`, "Wallet");
      const settingsDoc = await getDoc(settingsDocRef);
      const minPayment = settingsDoc.exists() ? settingsDoc.data().minWalletPayment || 0 : 0;

      setWalletBalance(totalBalance);
      setMinPaymentAmount(minPayment / 100);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  };

  const walletUsage = useMemo(() => {
    const discountedSubtotal = checkoutData.subtotal - checkoutData.appliedDiscount.discountAmount;
    const totalCost = discountedSubtotal + checkoutData.shippingCost;

    // Wallet deduction must ensure total doesn't drop below the minimum payment amount
    const walletDeduction = Math.min(checkoutData.walletBalance, Math.max(0, totalCost - checkoutData.minPaymentAmount));

    const remainingAmount = totalCost - walletDeduction;

    return { walletDeduction, remainingAmount };
  }, [checkoutData]);

  const totalAmount = useMemo(() => {
    // Use wallet deduction to calculate the final amount
    const { walletDeduction } = walletUsage;
    const discountedSubtotal = checkoutData.subtotal - checkoutData.appliedDiscount.discountAmount;
    const totalWithShipping = discountedSubtotal + checkoutData.shippingCost;
    const finalTotal = Math.max(checkoutData.minPaymentAmount, totalWithShipping - walletDeduction);

    return finalTotal.toFixed(2);
  }, [walletUsage, checkoutData]);

  //
  //
  // ADDRESS

  useEffect(() => {
    const fetchAddresses = () => {
      if (!loading && user?.addresses?.length) {
        setAddresses(user.addresses);
      }
    };

    fetchAddresses();
  }, [firestore, collectionPath, loading, user]);

  //
  //
  // WALLET PAYMENT

  const usedWalletAmount = useMemo(() => {
    if (walletBalance === undefined) return 0;

    const totalCost = validSubtotal + validShippingCost;
    const discountAmount = appliedDiscount?.discountAmount || 0;
    const netCostAfterDiscount = totalCost - discountAmount;

    const maxWalletUsage = Math.max(0, netCostAfterDiscount - minPaymentAmount / 100);
    return Math.min(walletBalance, maxWalletUsage);
  }, [walletBalance, validSubtotal, validShippingCost, appliedDiscount, minPaymentAmount]);

  const handleWalletOnlyPayment = async () => {
    setIsLoading(true);
    try {
      const { walletDeduction } = walletUsage;

      if (walletDeduction <= 0) {
        alert("Wallet deduction amount is invalid.");
      }

      let currentEmail = user.email;
      if (!currentEmail) {
        currentEmail = addresses[selectedAddressIndex]?.email;
      }

      if (!currentEmail) {
        alert("Please provide your address and email address to finish order.");
        setIsLoading(false);
        return;
      }

      // Save wallet-only payment details to Firestore
      await addDoc(collection(firestore, `${collectionPath}Wallets/UserWallets/DataBase`), {
        user_uid: user.uid,
        wallet_value: -walletDeduction.toFixed(2),
        description: `Order Payment for Cart ID: ${checkoutData.cartID}`,
        depositType: "order_payment",
        date_claimed: new Date(),
        date_created: new Date(),
      });

      // shippment Address
      const shipmentAddress = addresses[selectedAddressIndex];
      // if there is no address, then add the email from user data
      if (!shipmentAddress?.email) {
        shipmentAddress.email = currentEmail;
      }

      // Mark the cart as paid with wallet
      await updateDoc(doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, checkoutData.cartID), {
        paymentType: "Wallet",
        walletDeduction: walletDeduction.toFixed(2),
        shipmentAddress: shipmentAddress,
      });

      // now need to make API call to walletWebhookPayment
      const walletWebhookPayment = environment === "staging" ? "/dev/payment/walletWebhookPayment" : "/payment/walletWebhookPayment";

      const payload = {
        cartID: checkoutData.cartID, // Directly pass cartID
        status: "PAID",
        paymentType: "Wallet",
        walletDeduction: walletDeduction.toFixed(2),
      };

      // Send the request
      const response = await axios.post(`${config.apiConfig.API_ENDPOINT_URL}${walletWebhookPayment}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Server response handling
      if (response.data.success) {
        navigate(`/payment-success?cart_id=${checkoutData.cartID}`);
      } else {
        throw new Error(response.data.error || "Order creation failed.");
      }

      navigate(`/payment-success?cart_id=${checkoutData.cartID}`);
    } catch (error) {
      console.error("Wallet payment error:", error);
      alert("Failed to process wallet-only payment.");
    } finally {
      setIsLoading(false);
    }
  };

  //
  //
  // STRIPE PAYMENT

  const fetchCheckoutSessionId = useCallback(
    async (currentEmail, user, passedCheckoutData) => {
      console.log("FUNCTION fetchCheckoutSessionId: START");

      // Validate `items`
      if (!items || items.length === 0) {
        console.error("No items in cart.");
        return null;
      }

      // Set default values for fields that might be missing
      const validAppliedDiscount = passedCheckoutData?.appliedDiscount || { discount: 0, discountAmount: 0 };

      const validCartID = cartID || "UNKNOWN_CART_ID";
      const validCartSooperstockID = cart_sooperstock_ID || "UNKNOWN_SOOPERSTOCK_ID";
      const validSubtotal = subtotal || 0;
      const validUsedWalletAmount = usedWalletAmount || 0;
      const finalAmount = passedCheckoutData?.finalAmount || 0;

      const discountAmount = validAppliedDiscount.discountAmount;

      // Calculate the remaining amount after wallet deduction
      const roundToCents = (amount) => Math.round(amount * 100) / 100;
      const remainingAmount = roundToCents(finalAmount - validUsedWalletAmount).toFixed(2);

      const discountMultiplier = 1 - validAppliedDiscount.percentage / 100; // Convert discount percentage to multiplier

      const lineItems = [];

      if (validUsedWalletAmount == 0) {
        items.forEach((item) => {
          const discountedItemPrice = Math.round(item.final_price * discountMultiplier * 100); // Apply discount per item and round
          lineItems.push({
            name: item.product_name,
            description: item.description,
            amount: discountedItemPrice, // Rounded price in cents
            currency: "usd",
            quantity: item.quantity,
          });
        });

        // Add shipment as a separate line item if applicable
        if (shippingCost > 0) {
          lineItems.push({
            name: "Shipping",
            description: "Shipping Cost",
            amount: Math.round(shippingCost * 100),
            currency: "usd",
            quantity: 1,
          });
        }
      } else {
        // If wallet is used, simplify to a single line item with the remaining payment
        lineItems.push({
          name: `Payment balance for ${cart_sooperstock_ID}`,
          description: `Remaining payment after Sooper Cash`,
          amount: Math.floor(remainingAmount * 100), // Amount in cents
          currency: "usd",
          quantity: 1,
        });
      }

      // Payload for Stripe
      const payload = {
        lineItems,
        client_reference_id: validCartID,
        totalAmount: Math.round(remainingAmount * 100),
        isStripeStaging: isStripeStaging || forceStagingPayment,
        redirectURL,
        customer_email: currentEmail || "",
        metadata: {
          discount_code: validAppliedDiscount.name || "No Discount Code",
          discount_amount: discountAmount.toFixed(2),
        },
      };

      const endpoint = isStripeStaging
        ? "https://us-central1-sooperstock-app.cloudfunctions.net/api/dev/payment/stripeCreateCheckoutSession"
        : "https://us-central1-sooperstock-app.cloudfunctions.net/api/payment/stripeCreateCheckoutSession";

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to create Stripe Checkout session.");
        }

        const data = await response.json();
        console.log("FUNCTION fetchCheckoutSessionId: SUCCESS", data);

        return {
          sessionId: data.sessionId,
          paymentIntentId: data.id,
          paymentData: data,
        };
      } catch (error) {
        console.error("Error fetching sessionId:", error);
        return null;
      }
    },
    [items, subtotal, shippingCost, appliedDiscount, usedWalletAmount, isStripeStaging, forceStagingPayment]
  );

  // Function to handle Stripe Checkout redirection
  const handleStripeCheckout = async () => {
    // analytics
    logAnalyticsEvent("checkout_stripe_button", {
      content_type: "checkout_button",
      page_title: environment == "staging" ? "STAGING - Checkout Page" : "Checkout Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
      cart_payment_method: "Stripe",
      cart_sooperstock_ID: checkoutData?.cartSooperstockID,
      cart_items: checkoutData?.items?.length,
      cart_subtotal: checkoutData?.subtotal,
      cart_shipping: checkoutData?.shippingCost,
      cart_discount: checkoutData?.appliedDiscount?.discountAmount,
      cart_wallet_balance: checkoutData?.walletBalance,
      cart_total: checkoutData?.finalAmount,
    });

    if (!user || !user.uid) {
      setShowLoginPrompt(true); // Show login prompt if user is not logged in
      return;
    }

    let currentEmail = user.email;
    if (!currentEmail) {
      currentEmail = addresses[selectedAddressIndex]?.email;
    }

    if (!currentEmail) {
      alert("Please provide your address and email address to finish order.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true); // Start loading
    await fetchCheckoutData();

    const { sessionId, paymentIntentId, paymentData } = await fetchCheckoutSessionId(currentEmail, user, checkoutData);

    if (sessionId) {
      const stripe = await stripePromise;

      try {
        const docRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cartID);
        await updateDoc(docRef, {
          stripeSessionId: sessionId,
          stripePaymentIntentId: paymentIntentId,
          stripePaymentData: paymentData,
          shipmentAddress: addresses[selectedAddressIndex],
          isAnonymous: user.isAnonymous,
          walletDeduction: usedWalletAmount.toFixed(2),
          paymentType: "Stripe",
        });

        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Stripe Checkout error:", error);
        }
      } catch (error) {
        console.error("Error during payment processing:", error);
      }
    } else {
      console.error("Failed to retrieve Checkout session ID.");
    }

    setIsLoading(false); // End loading
  };

  //
  //
  //
  //

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <div style={styles.checkoutContainer}>
        <h2 style={styles.orderTitle}>Place Your Order</h2>
        <div style={styles.address}>
          <AddressCheckout
            addresses={addresses}
            setAddresses={setAddresses}
            selectedAddressIndex={selectedAddressIndex}
            setSelectedAddressIndex={setSelectedAddressIndex}
            user={user}
          />
        </div>
        <div style={styles.summary}>
          <h3 style={styles.summaryTitle}>Summary</h3>
          <div style={styles.summaryRow}>
            <span>Items</span>
            <span>${validSubtotal.toFixed(2)}</span>
          </div>
          {checkoutData.appliedDiscount.discountAmount > 0 && (
            <div style={styles.summaryRow}>
              <span>Promotion ({checkoutData.appliedDiscount.name}):</span>
              <span>- ${checkoutData.appliedDiscount.discountAmount.toFixed(2)}</span>
            </div>
          )}
          <div style={styles.summaryRow}>
            <span>Subtotal:</span>
            <span>${(validSubtotal - (checkoutData.appliedDiscount.discountAmount || 0)).toFixed(2)}</span>
          </div>
          <div style={styles.summaryRow}>
            <span>Shipping:</span>
            <span>{checkoutData.subtotal >= checkoutData.freeShippingThreshold ? "FREE" : `$${checkoutData.shippingCost.toFixed(2)}`}</span>
          </div>
          {walletUsage.walletDeduction > 0 && (
            <div style={styles.summaryRow}>
              <span>Wallet Deduction:</span>
              <span>- ${walletUsage.walletDeduction.toFixed(2)}</span>
            </div>
          )}
          <div style={styles.subtotalRow}>
            <span>Total:</span>
            <span style={styles.total}>${totalAmount}</span>
          </div>
        </div>
        {isLoading ? (
          <button
            style={{
              ...styles.purchaseButton,
              backgroundColor: "gray",
              cursor: "not-allowed",
              opacity: 0.6,
            }}
            disabled
          >
            Loading...
          </button>
        ) : walletUsage.remainingAmount === 0 ? (
          <button
            style={{
              ...styles.purchaseButton,
              backgroundColor: addresses.length > 0 ? "black" : "gray",
              cursor: addresses.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={handleWalletOnlyPayment}
            disabled={addresses.length === 0 || isLoading}
          >
            {isLoading ? "Processing..." : "Pay"}
          </button>
        ) : (
          <button
            style={{
              ...styles.purchaseButton,
              backgroundColor: addresses.length > 0 ? "black" : "gray",
              cursor: addresses.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={handleStripeCheckout}
            disabled={addresses.length === 0 || isLoading}
          >
            {isLoading ? "Processing..." : "Proceed to Payment"}
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  checkoutContainer: { padding: "20px", margin: "0 auto", fontFamily: "'Inter', sans-serif" },
  orderTitle: { fontSize: "18px", marginBottom: "20px" },
  address: { marginBottom: "20px" },
  addressBox: { border: "1px solid #ddd", padding: "15px" },
  headerText: { fontSize: "14px", color: "gray", marginBottom: "10px" },
  nameText: { fontSize: "16px", fontWeight: "bold" },
  addressText: { fontSize: "14px" },
  linkButton: { fontSize: "14px", color: "blue", cursor: "pointer" },
  purchaseButton: { width: "100%", padding: "15px", fontSize: "16px", color: "white", border: "none" },
  //
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    fontWeight: "bold",
  },
  total: {
    color: "#1fd400",
  },
  checkoutButton: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    marginTop: "20px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
  },
  summary: {
    marginTop: "10px",
    padding: "10px 0",
    borderTop: "8px solid #ececeb",
  },
  summaryTitle: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    fontSize: "14px",
    padding: "3px 0",
  },
};

export default Checkout;
