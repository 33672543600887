import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { collection, getDocs } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../context/EnvContext";
import config from "../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const FeaturedCarousel_Desktop = ({ featuredCollection }) => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasContent, setHasContent] = useState(true);
  const [visibleCards, setVisibleCards] = useState(4);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Fetch Featured Products
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        setLoading(true);
        const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
        const featuredSnapshot = await getDocs(featuredCollectionRef);

        const featuredData = featuredSnapshot.docs
          .map((doc) => {
            const productData = doc.data();
            return {
              id: doc.id,
              featureCollection_name: productData.featureCollection_name || "N/A",
              sorting: productData.sorting || 0,
              ...productData,
            };
          })
          .filter((product) => product.featureCollection_name === featuredCollection && product.enabled === true)
          .sort((a, b) => a.sorting - b.sorting);

        setFeaturedProducts(featuredData);
        setHasContent(featuredData.length > 0);
      } catch (error) {
        console.error("Error fetching featured products:", error);
        setHasContent(false);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedProducts();
  }, [db, collectionPath, featuredCollection]);

  // Update the number of visible cards based on screen size
  const updateVisibleCards = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1200) setVisibleCards(4);
    else if (windowWidth >= 992) setVisibleCards(3);
    else if (windowWidth >= 768) setVisibleCards(2);
    else setVisibleCards(1);
  };

  useEffect(() => {
    updateVisibleCards();
    window.addEventListener("resize", updateVisibleCards);
    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  // Scroll Function (Fixing Alignment Issues)
  const scrollTo = (direction) => {
    if (!containerRef.current) return;

    const cardWidth = containerRef.current.firstChild.offsetWidth + 20; // Including margin
    const containerWidth = containerRef.current.offsetWidth;
    const maxScroll = containerRef.current.scrollWidth - containerWidth;

    let newPosition =
      direction === "right" ? Math.min(scrollPosition + cardWidth * visibleCards, maxScroll) : Math.max(scrollPosition - cardWidth * visibleCards, 0);

    containerRef.current.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });

    setScrollPosition(newPosition);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <GridLoader />
      </div>
    );
  }

  if (!hasContent) {
    return <div style={{ textAlign: "center", marginTop: "20px" }}></div>;
  }

  return (
    <div style={styles.wrapper}>
      {featuredProducts.length > visibleCards && (
        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <div style={styles.arrow} onClick={() => scrollTo("left")}>
            <KeyboardArrowLeftIcon style={{ fontSize: 34 }} />
          </div>
          <div style={styles.arrow} onClick={() => scrollTo("right")}>
            <KeyboardArrowRightIcon style={{ fontSize: 34 }} />
          </div>
        </div>
      )}
      <div
        ref={containerRef}
        style={{
          ...styles.carouselContainer,
          justifyContent: featuredProducts.length <= visibleCards ? "center" : "flex-start", // Center when fewer cards
        }}
      >
        {featuredProducts.map((product, index) => (
          <div
            key={index}
            onClick={() => {
              if (product.featured_url.startsWith("http://") || product.featured_url.startsWith("https://")) {
                window.location.href = product.featured_url;
              } else {
                navigate(product.featured_url);
              }
            }}
            style={{
              ...styles.card,
              width:
                featuredProducts.length <= visibleCards
                  ? `calc(100% / ${featuredProducts.length} - 10px)` // Full width for fewer cards
                  : `calc(100% / ${visibleCards} - 20px)`, // Standard width
              marginRight: index === featuredProducts.length - 1 ? "0px" : "10px", // No margin for the last card
            }}
          >
            <img src={product.image_url || "https://placehold.co/800x450"} alt={product.title} style={styles.image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCarousel_Desktop;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    padding: "20px 0",
    overflow: "hidden",
  },
  carouselContainer: {
    display: "flex",
    overflowX: "hidden",
    flex: 1,
    scrollBehavior: "smooth",
    paddingRight: "10px",
    justifyContent: "flex-start",
  },
  card: {
    flex: "0 0 auto",
    borderRadius: "12px",
    border: "1px solid #e0e0e0",
    overflow: "hidden",
    position: "relative",
    background: "#fafafa",
    aspectTatio: "16/9",
    maxHeight: "190px",
    margin: "0 0px 0 10px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  arrow: {
    border: "none",
    borderRadius: "50%",
    padding: "10px",
    cursor: "pointer",
  },
};
