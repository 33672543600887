import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext";
import "../../styles/global.css";
import HeaderCategory from "../../elements/HeaderCategory";
import ListItem from "../../elements/ListItem";
import { borderRadius, display, height, textAlign, width } from "@mui/system";
import { text } from "@fortawesome/fontawesome-svg-core";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import Footer from "../../elements/Footer";
import FeaturedCarousel_Mobile from "../../elements/FeaturedCarousel_Mobile";

const CategoryPage = () => {
  const { catName } = useParams(); // Get category name from the URL
  const db = useFirestore(); // Firestore instance
  // environment
  const environment = useContext(EnvContext);
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const applyFilters = (filters = {}, searchTerm = "") => {
    console.log("applyFilters called with:", filters, searchTerm);
    // No filtering logic for now, but you can customize this later if needed.
  };

  // reload page rubber banding
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < -50) {
        // User pulled down (rubber banding)
        window.location.reload(); // Reload the page
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, `${collectionPath}Listings/ListingsList/DataBase`),
      (snapshot) => {
        const normalizedCategory = catName.trim().toLowerCase(); // Normalize the category from URL
        const currentDate = new Date();

        let filteredProducts = [];

        if (normalizedCategory === "deals") {
          // Filter deals: products with valid sale dates and prices
          filteredProducts = snapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((product) => {
              const startDate = new Date(product.listing_sale_startDate);
              const endDate = new Date(product.listing_sale_endDate);
              return product.listing_sale_price && currentDate >= startDate && currentDate <= endDate;
            });
        } else {
          // Fetch allowed categories for the current category from config
          const categoryConfig = config.categories.find((category) => category.value.toLowerCase() === normalizedCategory);
          const allowedCategories = categoryConfig?.product_category || []; // Default to an empty array if no match

          filteredProducts = snapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((product) => {
              // Ensure product_category exists and matches any allowed category
              return (
                product.product_category &&
                allowedCategories.some((allowed) => product.product_category.trim().toLowerCase().includes(allowed.trim().toLowerCase()))
              );
            });
        }

        // Sort products: in-stock first, out-of-stock last
        filteredProducts.sort((a, b) => {
          if (a.listing_stock_amount === 0 && b.listing_stock_amount !== 0) return 1;
          if (a.listing_stock_amount !== 0 && b.listing_stock_amount === 0) return -1;
          return 0;
        });

        setProducts(filteredProducts);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup on component unmount
  }, [db, catName, collectionPath]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="category-page" style={{ minHeight: "100vh", display: "flex", flex: 1, flexDirection: "column" }}>
      <HeaderCategory categoryName={catName} applyFilters={applyFilters} hideCategories={true} />
      <FeaturedCarousel_Mobile featuredCollection={`category_${catName.trim().toLowerCase()}`} />
      <div style={styles.productGrid}>
        {products.length > 0 ? (
          products.map((product) => <ListItem key={product.id} product={product} displayType={"horisontal"} />)
        ) : (
          <div style={styles.noProducts}>No products found in this category.</div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  productGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    padding: "0px 0px",
    flex: 1,
    alignContent: "start",
  },
  noProducts: {
    textAlign: "center",
    padding: "50px 0",
    fontSize: "18px",
    opacity: 0.7,
  },
  backButton: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    margin: "5px 0px 0 15px",
    background: "rgba(100,100,100,.07)",
    cursor: "pointer",
    borderRadius: "50%",
    paddingTop: "5px",
  },
};

export default CategoryPage;
