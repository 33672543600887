import React, { Component } from "react";
import { Dialog, DialogContent, DialogTitle, TextField, Button, List, ListItem, ListItemText, Box } from "@mui/material";
import Cookies from "js-cookie";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

class StateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filteredStates: [],
      temporarySelectedState: "", // Used only for dialog selection
      confirmedSelectedState: props.selectedState || <i data-feather={"map-pin"} style={{ width: "16px", height: "16px" }} />, // Displayed on the button
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    const { availableStates, onSave } = this.props;

    // Sort available states
    const sortedStates = [...(availableStates || [])].sort((a, b) => a.name.localeCompare(b.name));
    this.setState({ filteredStates: sortedStates });

    // Get the state from the cookie
    const cookieStateShortName = Cookies.get("selectedState");
    if (cookieStateShortName) {
      const cookieState = sortedStates.find((state) => state.shortName === cookieStateShortName);

      if (cookieState) {
        this.setState({
          temporarySelectedState: cookieState,
          confirmedSelectedState: cookieState.name,
        });

        // Notify parent of the saved state
        if (onSave) {
          onSave(cookieState.shortName);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { availableStates, selectedState } = this.props;

    // Update state if props change
    if (availableStates !== prevProps.availableStates) {
      const sortedStates = [...(availableStates || [])].sort((a, b) => a.name.localeCompare(b.name));
      this.setState({ filteredStates: sortedStates });
    }

    if (selectedState !== prevProps.selectedState) {
      this.setState({
        temporarySelectedState: selectedState || "",
        confirmedSelectedState: selectedState || "",
      });
    }
  }

  handleInputChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    const { availableStates } = this.props;

    // Filter states based on the input
    const filteredStates = (availableStates || []).filter((state) => state.name.toLowerCase().includes(searchText));

    this.setState({ searchText, filteredStates });
  };

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleStateSelect = (state) => {
    // Temporarily set selected state for dialog display (both name and shortName)
    this.setState({ temporarySelectedState: state });
  };

  handleSave = () => {
    const { temporarySelectedState } = this.state;
    const { onSave } = this.props;

    if (temporarySelectedState) {
      Cookies.set("selectedState", temporarySelectedState.shortName, { expires: 7 });

      if (onSave) {
        onSave(temporarySelectedState.shortName);
      }

      this.setState({
        confirmedSelectedState: temporarySelectedState.name,
      });

      this.handleCloseDialog();
    }
  };

  render() {
    const { searchText, filteredStates, temporarySelectedState, confirmedSelectedState, isDialogOpen } = this.state;

    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* Trigger Button */}
        <div onClick={this.handleOpenDialog} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
          {confirmedSelectedState}
        </div>

        {/* Dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={this.handleCloseDialog}
          fullWidth
          PaperProps={{
            style: {
              borderRadius: "15px",
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              maxWidth: "100%",
              height: "90%",
              margin: 0,
            },
          }}
        >
          {/* Modal Header */}
          <DialogTitle style={{ textAlign: "center" }}>Enter Shipping State</DialogTitle>
          <DialogContent>
            <p style={{ textAlign: "center", color: "#555", marginBottom: 20 }}>
              By entering your state, we will be able to show you products available in your area.
            </p>

            {/* Search Field */}
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search for your state"
              value={searchText}
              onChange={this.handleInputChange}
              style={{ marginBottom: "20px" }}
            />

            {/* State List */}
            <Box
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: "5px",
              }}
            >
              <List>
                {filteredStates.map((state) => (
                  <ListItem
                    button
                    key={state.shortName}
                    onClick={() => this.handleStateSelect(state)}
                    selected={state.name === temporarySelectedState}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItemText primary={`${state.name} (${state.shortName})`} />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Save Button */}
            <Button variant="outlined" color="red" fullWidth onClick={this.handleSave} disabled={!temporarySelectedState} style={{ marginTop: "20px" }}>
              Save
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default StateSelector;
