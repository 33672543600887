// src/elements/HeaderShop.js

import React, { useEffect, useState, useContext } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext";
import { getAuth } from "firebase/auth";
import countCartItems from "../functions/countCartItems";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../context/EnvContext";
import { useSwipeable } from "react-swipeable";
import Tooltip from "@mui/material/Tooltip";
import config from "../config";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import StateSelector from "./StateSelector";
import Cookies from "js-cookie";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EnveironmentStatus from "./EnveironmentStatus";

import { logAnalyticsEvent } from "../firebase/firebaseConfig";
import { borderRadius, display, fontSize } from "@mui/system";

// mui x font

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const HeaderCategory = ({ categoryName }) => {
  // environment
  const environment = useContext(EnvContext);
  //
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance

  const searchContext = useSearch(); // Use search context
  const { setSearchTerm, searchProducts, storeSearchTerm, getHistoryTerms, toggleRecentSearches, showRecentSearches } = searchContext || {}; // Destructure with fallback

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // config helper
  const getCategoryTitle = (value) => {
    if (!value) return null; // Handle invalid input

    // Normalize input (lowercase & remove spaces)
    const normalizedValue = value.toLowerCase().replace(/\s+/g, "");

    // Find matching category (also normalize category values)
    const category = config.categories.find((category) => category.value.toLowerCase().replace(/\s+/g, "") === normalizedValue);

    return category ? category.title : null; // Return title if found, otherwise null
  };

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user?.uid) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount, collectionPath);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  return (
    <>
      <EnveironmentStatus />
      <header
        className="header-shop"
        style={{
          ...styles.headerShop,
        }}
      >
        {/** HEADER */}
        <div style={{ ...styles.header.container }}>
          {/** HEADER FIRST BLOCK*/}
          <div style={{ ...styles.menu.container }}>
            <div style={styles.backButton} onClick={() => window.history.back()}>
              <ArrowBackIcon style={{ fontSize: 24 }} />
            </div>
          </div>
          <div style={{ ...styles.title.container }}>{getCategoryTitle(categoryName)}</div>
          {/** HEADER THIRD BLOCK*/}
          <div style={{ ...styles.cart.container }}>
            {/** CART ICON */}
            <Tooltip
              title={user && user.uid ? "" : "Start shopping or create an account to add items to your cart"}
              placement="left-start"
              disableHoverListener={!!(user && user.uid)}
            >
              <div
                style={{
                  opacity: user && user.uid ? 1 : 0.5,
                  cursor: user && user.uid ? "pointer" : "none",
                }}
                onClick={
                  user && user.uid
                    ? () => {
                        // Track the cart click event
                        logAnalyticsEvent("select_content", {
                          content_type: "cart",
                          item_id: "cart_page",
                          environment: environment,
                        });

                        // Navigate to the cart page
                        navigate("/cart");
                      }
                    : null
                }
              >
                <div style={{ width: "16px", height: "16px", position: "relative" }}>
                  <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
                  <div style={styles.cartCount}>{user && user.uid ? cartCount : 0}</div> {/* Display current number of items in cart */}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
        {/** SEARCH AREA */}
      </header>
    </>
  );
};

// Styles for HeaderShop component (unchanged)
const styles = {
  headerShop: {},
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
    },
  },
  menu: {
    container: {
      flex: 1,
      paddingLeft: "10px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  },
  cart: {
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      paddingRight: "20px",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
    // drop shadow
    boxShadow: "0px 3px 5px rgba(0,0,0,.1)",
  },

  //
  title: {
    container: {
      flex: 1,
      textAlign: "center",
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "600",
      letterSpacing: ".2px",
    },
  },

  //
  backButton: {
    display: "flex",
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default HeaderCategory;
