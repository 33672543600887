import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth, db } from "../firebase/firebaseConfig"; // Import auth and db from the initialized Firebase config
import { FirestoreContextProvider } from "../firebase/FirestoreContext"; // Import the FirestoreContextProvider
import ShopPage from "../screens/app/ShopPage";
import SignUp from "../screens/auth/SignUp";
import SignIn from "../screens/auth/SignIn";
import Listing from "../screens/app/Listing";
import Cart from "../screens/app/Cart";
import Checkout from "../screens/app/Checkout";
import UserAccount from "../screens/app/UserAccount";
import Help from "../screens/app/Help";
import Share from "../screens/app/Share";
import Legal from "../screens/app/Legal";
import UsersAddresses from "../screens/app/UserAddresses";
import OrderHistory from "../screens/app/OrderHistory";
import PaymentSuccess from "../screens/app/PaymentSuccess";
import FAQ from "../screens/app/FAQ";
import { onAuthStateChanged } from "firebase/auth";
import NewsletterUnsubscribe from "../screens/app/NewsletterUnsubscribe";
import RorgottenPass from "../screens/auth/ForgottenPass";
import CategoryPage from "../screens/app/CategoryPage";
import ProductRequest from "../screens/app/ProductRequest";

const AppRouter = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    // Clean up the Auth listener when the component unmounts
    return () => unsubscribeAuth();
  }, []);

  if (loading) {
    // Show a loading indicator while waiting for auth state
    return <div className="loading">Loading...</div>;
  }

  return (
    <FirestoreContextProvider db={db}>
      {/* Wrap the Router with FirestoreContextProvider */}
      <Router>
        <Routes>
          {/* Main shop page */}
          <Route path="/" element={<ShopPage />} />
          {/* Sign in page */}
          <Route path="/signin" element={<SignIn />} />
          {/* Sign Up page */}
          <Route path="/signup" element={<SignUp />} />
          {/* Listing detail page with dynamic listingID */}
          <Route path="/listing/:listingID" element={<Listing />} />
          {/* Listing detail page with dynamic listingID */}
          <Route path="/category/:catName" element={<CategoryPage />} />
          {/* Cart page */}
          <Route path="/cart" element={<Cart />} />
          {/* Checkout page */}
          <Route path="/checkout" element={<Checkout />} />
          {/* User account page */}
          <Route path="/useraccount" element={user ? <UserAccount /> : <Navigate to="/signin" />} />
          {/* Help page */}
          <Route path="/help" element={<Help />} />
          {/* Legal page */}
          <Route path="/legal" element={<Legal />} />
          {/* Saved addresses page */}
          <Route path="/useraddresses" element={<UsersAddresses />} />
          {/* Order history page */}
          <Route path="/orderhistory" element={user ? <OrderHistory /> : <Navigate to="/signin" />} />
          {/* Payment success page */}
          <Route path="/payment-success" element={<PaymentSuccess />} />
          {/* Newsletter unsubscribe page */}
          <Route path="/newsletter-unsubscribe" element={<NewsletterUnsubscribe />} />
          {/* FAQ page */}
          <Route path="/faq" element={<FAQ />} />
          {/* Share page */}
          <Route path="/share" element={<Share />} />
          {/* Forgotten password page */}
          <Route path="/forgotten-pass" element={<RorgottenPass />} />
          {/* Product request page */}
          <Route path="/product-request" element={<ProductRequest />} />
        </Routes>
      </Router>
    </FirestoreContextProvider>
  );
};

export default AppRouter;
