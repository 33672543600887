// HorizontalListing.js
import React, { Component } from "react";
import ListItem from "./ListItem";
import "../styles/global.css";
import { borderRadius, fontSize } from "@mui/system";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

class HorizontalListing extends Component {
  render() {
    const { title, products, categoryLink, onNavigate, limit = 10, displayShowAllArrow = true } = this.props;

    return (
      <div className="horizontal-listing" style={{ paddingTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={styles.title}>{title}</div>
          {displayShowAllArrow === true && (
            <div style={styles.backButton} onClick={() => onNavigate(categoryLink)}>
              <ArrowForwardIcon style={{ fontSize: 21 }} />
            </div>
          )}
        </div>
        <div className="horizontal-listing-container" style={styles.container}>
          {products && products.length > 0 ? (
            <>
              {products.slice(0, limit).map((product, index) => (
                <div key={product.id || index} style={styles.item}>
                  <ListItem product={product} index={index} dataMultiple={products} />
                </div>
              ))}
            </>
          ) : (
            <p style={styles.empty}>No products found.</p>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    overflowX: "auto",
    padding: "10px 0",
    gap: "10px",
  },
  title: {
    fontSize: 22,
    fontWeight: "600",
    margin: "10px 10px",
  },
  item: {
    flex: "0 0 auto",
    width: "150px", // Adjust based on your design
  },
  empty: {
    fontSize: "1rem",
    color: "gray",
    marginLeft: "10px",
  },
  showMore: {
    width: "120px",
    height: "120px",
    display: "flex",
    backgroundColor: "#f5f5f5",
    margin: "15px 0",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    color: "gray",
    cursor: "pointer",
  },
  //
  backButton: {
    display: "flex",
    backgroundColor: "rgba(100,100,100,.07)",
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
  },
};

export default HorizontalListing;
