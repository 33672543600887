import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../../firebase/firebaseConfig";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import Header from "../../elements/Header";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import Footer from "../../elements/Footer";

export const OrderHistory = ({}) => {
  //
  const environment = useContext(EnvContext);
  //
  const { user } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedOrder, setExpandedOrder] = useState(null);

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    if (user) {
      const q = query(collection(db, `${collectionPath}Orders/OrdersList/DataBase`), where("user_id", "==", user.uid));
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const fetchedOrders = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setOrders(fetchedOrders);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching orders: ", error);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  const renderOrderSection = (title, filteredOrders) => (
    <div style={styles.sectionContainer}>
      <h2 style={styles.h2}>{title}</h2>
      <div style={styles.content}>
        {filteredOrders.map((order) => {
          let total_price = 0;
          total_price = order.items.reduce((total, item) => total + (item?.price?.finalPrice || 0) * (item.quantity || 0), 0).toFixed(2);
          if (order?.shippingCost) {
            total_price = (parseFloat(total_price) + parseFloat(order.shippingCost / 100)).toFixed(2);
          }

          let orderStatus = "";
          switch (order?.status) {
            case "in_progress":
              orderStatus = "Preparing";
              break;
            case "pending":
              orderStatus = "Pending";
              break;
            case "complete":
              orderStatus = "Complete";
              break;
            case "shipped":
              orderStatus = "Shipped";
              break;
            case "delivered":
              orderStatus = "Delivered";
              break;
            case "canceled":
              orderStatus = "Canceled";
              break;
            case "returned":
              orderStatus = "Returned";
              break;
            case "reviewing":
              orderStatus = "Reviewing";
              break;
            default:
              orderStatus = "Pending";
          }

          return (
            <div key={order.id} style={{ ...styles.orderCard }}>
              <div style={{ ...styles.orderCardHeader, display: "flex" }}>
                <img
                  src={order?.items?.[0]?.images?.[0]?.length > 0 ? order.items[0].images[0] : "/images/no_product_image.png"}
                  alt={order?.items?.[0]?.product_name || "No product image"}
                  style={styles.productImage}
                />
                <div style={styles.orderDetails}>
                  <div style={styles.orderHeader}>
                    <h3 style={styles.productName}>{order?.items[0].product_name}</h3>
                    <span style={styles.orderLink} onClick={() => toggleOrderDetails(order.id)}>
                      {order?.items.length > 1 ? (expandedOrder === order.id ? "hide details" : "show details") : ""}
                    </span>
                  </div>

                  <div style={{ ...styles.orderInfo, paddingTop: 0 }}>
                    Order Id: {order?.sooperstock_order_id.split("-")[0]} | Status: {orderStatus}
                  </div>
                  <p style={styles.orderInfo}>
                    Placed on: {moment(order?.created_at && order?.created_at.toDate()).format("YYYY-MM-DD")} | Items: {order.items.length || 0}{" "}
                  </p>
                  <p style={{ ...styles.orderInfo }}>
                    <span style={{ ...styles.price, marginLeft: 0 }}>Order total: ${total_price}</span>
                    <br />
                  </p>
                </div>
              </div>
              <div style={{ display: "flex" }} />
              {expandedOrder === order.id && (
                <div style={{ ...styles.orderItems }}>
                  {order.items.length > 1 &&
                    order.items.map((item, index) => (
                      <div key={item.id} style={styles.itemDetails}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: 13,
                          }}
                        >
                          <div>
                            <img src={item?.images && item?.images.length > 0 ? item?.images[0] : "/images/no_product_image.png"} style={styles.productImage} />
                          </div>
                          <div
                            style={{
                              fontWeight: "500",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {item.product_name}
                              <br />({item.brand_name}) | Qty: {item.quantity},{" "}
                              <span
                                style={{
                                  color: "#1fd400",
                                  fontWeight: "600",
                                }}
                              >
                                ${item?.price?.finalPrice}
                              </span>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    ))}

                  {order?.shippingCost > 0 && (
                    <div style={styles.itemDetails}>
                      <div>
                        <p
                          style={{
                            fontSize: 13,
                            marginLeft: 65,
                            padding: "10px 0",
                          }}
                        >
                          <span style={{ fontWeight: "500" }}>Shipping</span> |{" "}
                          <span style={{ color: "#1fd400", fontWeight: "600" }}>${parseFloat(order.shippingCost / 100).toFixed(2)}</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  const currentOrders = orders.filter((order) => order.status !== "complete");
  const pastOrders = orders.filter((order) => order.status === "complete");

  return (
    <div style={styles.container}>
      <Header />
      {renderOrderSection("Current order(s)", currentOrders)}
      {renderOrderSection("Past orders", pastOrders)}
      <Footer />
    </div>
  );
};

const styles = {
  container: {},
  content: {
    padding: "20px",
  },
  sectionContainer: {
    marginTop: "20px",
  },
  h2: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
    padding: "0px 20px",
    marginTop: "20px",
  },
  orderCard: {
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  orderCardHeader: {
    display: "flex",
    flex: 1,
  },
  productImage: {
    width: "50px",
    height: "50px",
    marginRight: "15px",
    objectCover: "contain",
  },
  orderDetails: {
    flex: 1,
  },
  orderHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productName: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 0,
  },
  orderLink: {
    fontSize: "14px",
    color: "#007BFF",
    cursor: "pointer",
    textDecoration: "underline",
  },
  orderInfo: {
    margin: "5px 0",
    color: "#666",
    fontSize: "14px",
  },
  price: {
    color: "#1fd400",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  orderItems: {
    marginTop: "10px",
  },
  itemDetails: {
    display: "flex",
    marginBottom: "10px",
  },
  itemImage: {
    width: "40px",
    height: "60px",
    marginRight: "10px",
  },
};

export default OrderHistory;
