import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where, doc, getDoc, updateDoc, addDoc, writeBatch } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext";
import handleQuantityChange from "../../functions/handleQuantityChange"; // Import the function
import handleDeleteItem from "../../functions/handleDeleteItem"; // Import the function
import { currentProductPrice } from "../../functions/currentProductPrice";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import { logAnalyticsEvent } from "../../firebase/firebaseConfig";
import Footer from "../../elements/Footer";

const Cart = () => {
  //
  const environment = useContext(EnvContext);
  //
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cart_sooperstock_ID, setCart_sooperstock_ID] = useState(null);
  const [shipmentPrice, setShipmentPrice] = useState(0);
  const [freeShipingThreshold, setFreeShipingThreshold] = useState(0);
  const [disabledItems, setDisabledItems] = useState({});
  const [finalShippingCost, setFinalShippingCost] = useState(0);

  // Discount code
  const [appliedDiscount, setAppliedDiscount] = useState(null); // For applied discount details
  const [discountCode, setDiscountCode] = useState(""); // Input for discount code
  const [discountMessage, setDiscountMessage] = useState("");
  const [discountMessageStyle, setDiscountMessageStyle] = useState({ color: "black" });
  const [isDiscountLoading, setIsDiscountLoading] = useState(false);

  //
  const [summary, setSummary] = useState({
    subtotal: 0,
    discountAmount: 0,
    calculatedShippingCost: 0,
    total: 0,
  });

  const firestore = useFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  let subtotal = 0;

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // analytics
  const pageAnalytics = () => {
    //
    logAnalyticsEvent("cart_view", {
      page_title: environment == "staging" ? "STAGING - Cart Page" : "Cart Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
      cart_sooperstock_ID: cart_sooperstock_ID,
      cart_items: cartItems.length,
      cart_total: summary.total,
    });
  };

  useEffect(() => {
    if (user) {
      fetchCartItems();
      fetchShipmentSettings();
    } else {
      navigate("/");
    }
  }, [user]);

  //
  //
  // SHIPPING SETTINGS
  const fetchShipmentSettings = async () => {
    try {
      const shipmentDocRef = doc(firestore, `${collectionPath}SooperstockSettings`, "Shipment");
      const shipmentDocSnapshot = await getDoc(shipmentDocRef);

      if (shipmentDocSnapshot.exists()) {
        const shipmentData = shipmentDocSnapshot.data();

        if (shipmentData?.freeShipmentThreshold) {
          setFreeShipingThreshold(shipmentData.freeShipmentThreshold);
        } else {
          console.error("No free shipping threshold defined in SooperstockSettings.");
        }

        if (shipmentData?.shipmentPrice) {
          setShipmentPrice(shipmentData.shipmentPrice); // Dynamically set shipping cost
          console.log("Shipment price fetched:", shipmentData.shipmentPrice); // Debug log
        } else {
          console.error("No shipping cost defined in SooperstockSettings.");
        }
      } else {
        console.error("No SooperstockSettings document found for Shipment.");
      }
    } catch (error) {
      console.error("Error fetching shipment settings:", error);
    }
  };

  //
  //
  // User Cart

  const fetchUserCart = async (firestore) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      return null;
    }

    const cartQuery = query(
      collection(firestore, `${collectionPath}Carts/CartsList/DataBase`),
      where("user_id", "==", user.uid),
      where("status", "==", "ordering")
    );

    const cartQuerySnapshot = await getDocs(cartQuery);

    if (!cartQuerySnapshot.empty) {
      setCart_sooperstock_ID(cartQuerySnapshot.docs[0].data()?.cart_sooperstock_ID + 123);
      return cartQuerySnapshot.docs[0]; // Return the first cart document
    }

    return null;
  };

  //
  //
  // CART ITEMS

  const fetchCartItems = async () => {
    try {
      const cartDoc = await fetchUserCart(firestore);

      if (cartDoc) {
        //
        // Update the opening_cart_page field in Firestore
        const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartDoc.id);
        await updateDoc(cartDocRef, {
          opening_cart_page: new Date(), // Firebase server timestamp
        });

        // Check for applied discount in the cart document
        const cartData = cartDoc.data();
        if (cartData.applied_discount_name) {
          setAppliedDiscount({
            code: cartData.applied_discount_name,
            name: cartData.applied_discount_name,
            discount: cartData.applied_discount_percentage,
            discountAmount: (subtotal * cartData.applied_discount_percentage) / 100, // Calculate based on subtotal
            minimumPurchaseAmount: cartData.applied_discount_minimum_purchase_amount,
            maxAmount: cartData.applied_discount_max_amount,
          });
          setDiscountCode(cartData.applied_discount_name); // Set discount code in input
        } else {
          setAppliedDiscount(null); // Clear if no discount
        }

        //
        const itemsRef = collection(firestore, `${collectionPath}Carts/CartsList/DataBase/${cartDoc.id}/Items`);
        const itemsSnapshot = await getDocs(itemsRef);

        const items = await Promise.all(
          itemsSnapshot.docs.map(async (doc) => {
            const itemData = doc.data();
            const priceInfo = await currentProductPrice(itemData); // Calculate finalPrice

            return {
              id: doc.id,
              cartID: cartDoc.id,
              ...itemData,
              finalPrice: itemData.final_price.toFixed(2), // Use finalPrice from currentProductPrice
            };
          })
        );

        // Sorting by seller_id
        const sortedItems = items.sort((a, b) => (a.seller_id > b.seller_id ? 1 : -1));

        setCart_sooperstock_ID(cartDoc.data()?.cart_sooperstock_ID);
        setCartItems(sortedItems);
        // call analytics
        pageAnalytics();
      } else {
        // Handle case where the cart is not found
        setCartItems([]);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //
  //
  // SUMMARY
  useEffect(() => {
    // Recalculate subtotal
    // const newSubtotal = cartItems.reduce((sum, item) => sum + (item.finalPrice || 0) * (item.quantity || 1), 0);

    // Check if a discount is applied and meets minimum purchase amount
    let newSubtotal = 0;
    let discountedTotal = 0;
    let discountAmount = 0;
    if (appliedDiscount?.discount > 0) {
      cartItems.forEach((item) => {
        const discountedItemPrice = Math.round(item.finalPrice * (1 - appliedDiscount.discount / 100) * 100) / 100;
        discountedTotal += discountedItemPrice * item.quantity;
      });

      newSubtotal = cartItems.reduce((sum, item) => sum + item.finalPrice * item.quantity, 0);
      discountAmount = newSubtotal - discountedTotal;
      discountAmount = Number(discountAmount.toFixed(2)); // Ensure proper rounding
    } else {
      newSubtotal = cartItems.reduce((sum, item) => sum + item.finalPrice * item.quantity, 0);
      discountedTotal = newSubtotal;
    }

    // Use the shipping cost fetched from SooperstockSettings
    const calculatedShippingCost = newSubtotal >= freeShipingThreshold ? 0 : shipmentPrice;

    // Final total calculation
    const total = newSubtotal - discountAmount + calculatedShippingCost;

    // Update the summary state
    setSummary({
      subtotal: newSubtotal,
      discountAmount: discountAmount || 0,
      calculatedShippingCost: calculatedShippingCost || 0,
      total: total || 0,
    });

    console.log("Summary updated:", {
      newSubtotal,
      discountAmount,
      calculatedShippingCost,
      total,
    });
  }, [cartItems, appliedDiscount, freeShipingThreshold, shipmentPrice]);

  //
  //
  // DISCOUNT CODE

  const handleApplyDiscountCode = async () => {
    setIsDiscountLoading(true);
    setDiscountMessage("Checking code...");
    setDiscountMessageStyle({ color: "black" });

    const discountsCollection = collection(firestore, `${collectionPath}Marketing/DiscountsCodes/DataBase`);
    const discountQuery = query(discountsCollection, where("code", "==", discountCode.toUpperCase()));

    try {
      const querySnapshot = await getDocs(discountQuery);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const data = docSnap.data();

        // Validate if discount code is active
        const today = new Date();
        if (data.startDate.toDate() > today || today > data.endDate.toDate()) {
          setDiscountMessage("Discount code expired.");
          setDiscountMessageStyle({ color: "red" });
          setIsDiscountLoading(false);
          return;
        }

        // Check if minimum purchase amount is met
        if (data.minimumPurchaseAmount && subtotal < data.minimumPurchaseAmount) {
          setDiscountMessage(`Minimum purchase of $${data.minimumPurchaseAmount} required.`);
          setDiscountMessageStyle({ color: "red" });
          setIsDiscountLoading(false);
          return;
        }

        // Fetch the user's cart to get cartID
        const cartDoc = await fetchUserCart(firestore);
        if (!cartDoc) {
          setDiscountMessage("No active cart found.");
          setDiscountMessageStyle({ color: "red" });
          setIsDiscountLoading(false);
          return;
        }

        const cartID = cartDoc.id; // Extract cartID from cartDoc

        // Overwrite existing discount code (remove any previous one for this cart)
        const usageCollection = collection(firestore, `${collectionPath}DiscountCodeUsage`);
        const cartQuery = query(usageCollection, where("cartID", "==", cartID));
        const cartSnapshot = await getDocs(cartQuery);

        const batch = writeBatch(firestore);
        cartSnapshot.forEach((doc) => {
          batch.delete(doc.ref); // Delete all existing records for this cart
        });
        await batch.commit(); // Execute the batch deletion

        // Check the user's usage count (USED only)
        const usageQuery = query(
          usageCollection,
          where("code", "==", discountCode),
          where("status", "==", "used"),
          where("user_email", "==", user?.email || "")
        );
        const usageSnapshot = await getDocs(usageQuery);
        const usedCount = usageSnapshot.size;

        if (usedCount >= data.limitPerUser) {
          setDiscountMessage(`This code can only be used ${data.limitPerUser} times.`);
          setDiscountMessageStyle({ color: "red" });
          setIsDiscountLoading(false);
          return;
        }

        // Calculate the discount amount with limits
        let calculatedDiscount = (subtotal * data.amount) / 100; // Assume percentage discount
        if (data.discountType === "fixed") {
          calculatedDiscount = data.amount; // Fixed amount discount
        }
        if (data.discountAmountLimit) {
          calculatedDiscount = Math.min(calculatedDiscount, data.discountAmountLimit); // Apply max limit
        }

        // Store a pending record
        await addDoc(usageCollection, {
          user_uid: user?.uid,
          user_email: user?.email || "",
          code: discountCode,
          cartID,
          status: "pending",
          appliedAt: new Date(),
        });

        // Apply discount to the cart
        const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartID);
        await updateDoc(cartDocRef, {
          applied_discount_name: data.code,
          applied_discount_id: data.id,
          applied_discount_percentage: data.amount,
          applied_discount_amount: calculatedDiscount,
          applied_discount_max_amount: data.discountAmountLimit || 0,
          applied_discount_minimum_purchase_amount: data.minimumPurchaseAmount || 0,
        });

        // Update UI state
        setAppliedDiscount({
          code: data.code,
          name: data.name,
          discount: data.amount,
          discountAmount: calculatedDiscount,
        });

        setDiscountMessage(`You saved $${calculatedDiscount.toFixed(2)}`);
        setDiscountMessageStyle({ color: "green" });
      } else {
        setDiscountMessage("Invalid discount code.");
        setDiscountMessageStyle({ color: "red" });
      }
    } catch (error) {
      console.error("Error applying discount:", error);
      setDiscountMessage("Failed to apply discount code.");
      setDiscountMessageStyle({ color: "red" });
    } finally {
      setIsDiscountLoading(false);
    }
  };

  useEffect(() => {
    if (appliedDiscount) {
      setDiscountMessage(`You saved $${summary.discountAmount.toFixed(2)}`);
      setDiscountMessageStyle({ color: "green" });
    } else {
      setDiscountMessage("");
    }
  }, [summary.discountAmount, appliedDiscount]);

  const handleRemoveDiscountCode = async () => {
    if (!appliedDiscount) return;

    try {
      // Fetch the user's cart to get cartID
      const cartDoc = await fetchUserCart(firestore);
      if (!cartDoc) return;

      const cartID = cartDoc.id;

      // Remove from DiscountCodeUsage collection
      const usageCollection = collection(firestore, `${collectionPath}DiscountCodeUsage`);
      const cartQuery = query(usageCollection, where("cartID", "==", cartID));
      const cartSnapshot = await getDocs(cartQuery);

      const batch = writeBatch(firestore);
      cartSnapshot.forEach((doc) => {
        batch.delete(doc.ref); // Delete all discount usage records for this cart
      });
      await batch.commit();

      // Remove discount from the cart document
      const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartID);
      await updateDoc(cartDocRef, {
        applied_discount_name: "",
        applied_discount_id: "",
        applied_discount_percentage: 0,
        applied_discount_amount: 0,
        applied_discount_max_amount: 0,
        applied_discount_minimum_purchase_amount: 0,
      });

      // Update UI state
      setAppliedDiscount(null);
      setDiscountMessageStyle({ color: "black" });
      setDiscountCode("");
      setDiscountMessage("");
    } catch (error) {
      console.error("Error removing discount code:", error);
      setDiscountMessage("Failed to remove discount code.");
      setDiscountMessageStyle({ color: "red" });
    }
  };

  //
  //
  // GO TO CHECKOUT

  const handleCheckout = async () => {
    const unavailableItems = [];

    // analytics
    logAnalyticsEvent("cart_page_procced_to_checkout_button", {
      content_type: "checkout_button",
      page_title: environment == "staging" ? "STAGING - Cart Page" : "Cart Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
      environment: environment,
      cart_sooperstock_ID: cart_sooperstock_ID,
      cart_items: cartItems.length,
      cart_total: summary.total,
    });

    // Check availability of each item in the cart
    for (let item of cartItems) {
      const itemDocRef = doc(firestore, `${collectionPath}Products`, item.seller_product_id); // Adjust path if needed
      const itemDocSnapshot = await getDoc(itemDocRef);

      if (itemDocSnapshot.exists()) {
        const itemData = itemDocSnapshot.data();

        // Check if product is out of stock or inactive
        if (itemData.listing_stock_amount <= 0 || itemData.status === "inactive") {
          unavailableItems.push(item);
        }
      }
    }

    if (unavailableItems.length > 0) {
      alert("One or more items in your bag are no longer available. Please refresh the page for current availability.");

      // Remove unavailable items from the cart
      const updatedCartItems = cartItems.filter((item) => !unavailableItems.includes(item));
      setCartItems(updatedCartItems);

      // Optionally, refresh the cart by refetching items from Firestore to ensure data consistency
      await fetchCartItems(); // Refresh cart to reflect updated availability
      return;
    }

    // Recalculate subtotal, discount, and total
    let discountAmount = 0;
    const newSubtotal = cartItems.reduce((sum, item) => sum + (item.finalPrice || 0) * (item.quantity || 1), 0);

    if (appliedDiscount?.discount > 0) {
      cartItems.forEach((item) => {
        const discountedItemPrice = Math.round(item.finalPrice * (1 - appliedDiscount.discount / 100) * 100) / 100;
        discountAmount += (item.finalPrice - discountedItemPrice) * item.quantity;
      });
      discountAmount = Number(discountAmount.toFixed(2)); // Ensure proper rounding
    }

    const finalShippingCost = newSubtotal >= freeShipingThreshold ? 0 : shipmentPrice;
    const finalAmount = newSubtotal - discountAmount + finalShippingCost;

    try {
      // Update Firestore with the final amount and shipping price
      const cartDocRef = doc(firestore, `${collectionPath}Carts/CartsList/DataBase`, cartItems[0].cartID);
      await updateDoc(cartDocRef, {
        subtotal: newSubtotal,
        shippingCost: finalShippingCost,
        applied_discount_name: appliedDiscount?.code || "",
        applied_discount_percentage: appliedDiscount?.discount || 0,
        applied_discount_amount: discountAmount,
        finalAmount: finalAmount,
        applied_discount_max_amount: appliedDiscount?.maxAmount || 0,
        applied_discount_minimum_purchase_amount: appliedDiscount?.minimumPurchaseAmount || 0,
      });

      // Navigate to the checkout page
      navigate("/checkout", {
        state: {
          cartID: cartItems.length ? cartItems[0].cartID : null,
          items: cartItems,
          cart_sooperstock_ID: cart_sooperstock_ID,
          subtotal: summary.subtotal,
          shippingCost: summary.calculatedShippingCost,
          total: finalAmount,
          freeShipingThreshold: freeShipingThreshold,
          finalAmount: finalAmount,
        },
      });
    } catch (error) {
      console.error("Error updating cart data for checkout: ", error);
      alert("Failed to proceed to checkout. Please try again.");
    }
  };

  const handleQuantityUpdate = async (item, quantityChange) => {
    setDisabledItems((prev) => ({ ...prev, [item.id]: true })); // Disable buttons for this item

    try {
      const itemDocRef = doc(firestore, `${collectionPath}Listings/ListingsList/DataBase`, item.id);
      const itemDocSnapshot = await getDoc(itemDocRef);

      if (itemDocSnapshot.exists()) {
        const productData = itemDocSnapshot.data();

        const maxAllowedQuantity =
          item.finalPrice?.type === "sooperdeal" ? productData.sooperdeal_limit_per_order || Infinity : productData.listing_limit_per_order || Infinity;

        const newQuantity = item.quantity + quantityChange;

        if (newQuantity > maxAllowedQuantity) {
          alert(`You can only order a maximum of ${maxAllowedQuantity} units of this product.`);
          return;
        } else if (newQuantity < 1) {
          // If quantity is less than 1, delete the item from the cart
          await handleDeleteItem(firestore, item.cartID, item.seller_product_id, item.quantity, setCartItems, collectionPath);
          fetchCartItems(); // Refresh the cart after the item is deleted
          return;
        }

        // Otherwise, update the quantity
        await handleQuantityChange(firestore, item.cartID, item.seller_product_id, quantityChange, setCartItems, collectionPath);

        // Fetch fresh cart items after update
        await fetchCartItems();
      } else {
        console.error("Product data not found for item:", item.product_id);
      }
    } catch (error) {
      console.error("Error updating quantity: ", error);
    } finally {
      setDisabledItems((prev) => ({ ...prev, [item.id]: false })); // Re-enable buttons for this item
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  // Map through cart items and accumulate subtotal
  cartItems.forEach((item) => {
    const itemTotal = (item.finalPrice || 0) * (item.quantity || 1);
    subtotal += itemTotal; // Accumulate subtotal here
  });

  //
  let currentSellerId = null;
  let packageCount = 0;

  return (
    <div>
      <Header />
      <div style={styles.cartContainer}>
        <div
          style={{
            color: "#1fd400",
            fontWeight: "600",
          }}
        >
          {summary.calculatedShippingCost !== 0 && "FREE shipping for orders over $100"}
        </div>

        <h3 style={styles.cartTitle}>
          Total{" "}
          <b>
            {cartItems.length} {cartItems.length === 1 ? "item" : "items"}
          </b>
        </h3>

        {cartItems.map((item, index) => {
          let displayPackageInfo = false;

          // Check if the seller_id changes and display package info
          if (item.seller_id !== currentSellerId) {
            currentSellerId = item.seller_id;
            packageCount++;
            displayPackageInfo = true;
          }

          return (
            <div key={item.id} style={{}}>
              {displayPackageInfo && (
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    marginTop: 10,
                    marginBottom: 6,
                  }}
                >
                  Package {packageCount} of {cartItems.filter((i, idx) => idx === 0 || cartItems[idx - 1].seller_id !== i.seller_id).length} (
                  {cartItems.filter((i) => i.seller_id === item.seller_id).length}{" "}
                  {cartItems.filter((i) => i.seller_id === item.seller_id).length > 1 ? "products" : "product"})
                </div>
              )}
              <div style={styles.cartItem}>
                <img src={(item?.images && item?.images[0]) || "https://placehold.jp/100x100.png"} alt="Product" style={styles.productImage} />
                <div style={styles.productDetails}>
                  <h4 style={styles.productTitle}>{item.product_name}</h4>
                  <div style={styles.productInfo}>
                    <span>
                      ${item?.finalPrice || 0} | {item?.quantity && `Qty: ${item?.quantity} | Total: $${(item?.finalPrice * item?.quantity).toFixed(2)}`}
                    </span>
                  </div>
                  <button
                    style={{
                      ...styles.deleteButton,
                      opacity: disabledItems[item.id] ? 0.5 : 1,
                      cursor: disabledItems[item.id] ? "not-allowed" : "pointer",
                    }}
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        setDisabledItems((prev) => ({
                          ...prev,
                          [item.id]: true,
                        })); // Disable the delete button for this item

                        try {
                          await handleDeleteItem(firestore, item.cartID, item.seller_product_id, item.quantity, setCartItems, collectionPath);
                          fetchCartItems(); // Refresh the cart after the item is deleted
                        } catch (error) {
                          console.error("Error deleting item: ", error);
                        } finally {
                          setDisabledItems((prev) => ({
                            ...prev,
                            [item.id]: false,
                          })); // Re-enable the delete button after the operation
                        }
                      }
                    }}
                    disabled={disabledItems[item.id]} // Disable the button while processing
                  >
                    delete item
                  </button>
                </div>
                <div style={styles.quantityControls}>
                  <p
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        await handleQuantityUpdate(item, 1); // Increase quantity
                      }
                    }}
                    style={{
                      ...styles.quantityButton,
                      borderBottom: "1px solid #ddd",
                      opacity: disabledItems[item.id] ? 0.5 : 1,
                    }}
                  >
                    +
                  </p>
                  <p
                    onClick={async () => {
                      if (!disabledItems[item.id]) {
                        await handleQuantityUpdate(item, -1); // Decrease quantity
                      }
                    }}
                    style={{
                      ...styles.quantityButton,
                      opacity: disabledItems[item.id] ? 0.5 : 1,
                    }}
                  >
                    -
                  </p>
                </div>
              </div>
            </div>
          );
        })}

        {/* Discount Code Section */}
        <div style={{ padding: "20px 0px" }}>
          <div style={{ ...styles.summaryRow, display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <div>
              <span>Discount Code:</span>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              {appliedDiscount ? (
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input type="text" value={appliedDiscount.code} style={{ ...styles.discountInput, borderRadius: 0 }} readOnly />
                    <div
                      onClick={() => {
                        handleRemoveDiscountCode();
                      }}
                      style={{
                        ...styles.discountButton,
                        backgroundColor: isDiscountLoading ? "gray" : "black",
                        opacity: isDiscountLoading ? 0.5 : 1,
                        cursor: "pointer",
                      }}
                    >
                      &#10005;
                    </div>
                  </div>
                  {discountMessage && <p style={discountMessageStyle}>{discountMessage}</p>}
                </div>
              ) : (
                <div style={{}}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="text"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                      placeholder="Enter Discount Code"
                      style={{ ...styles.discountInput, borderRadius: 0 }}
                    />
                    <div
                      onClick={handleApplyDiscountCode}
                      disabled={isDiscountLoading}
                      style={{
                        ...styles.discountButton,
                        backgroundColor: isDiscountLoading ? "gray" : "black",
                        opacity: isDiscountLoading ? 0.5 : 1,
                        cursor: "pointer",
                      }}
                    >
                      {isDiscountLoading ? "Checking..." : "Apply"}
                    </div>
                  </div>
                  {<div style={{ ...discountMessageStyle }}>{discountMessage}</div>}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Summary section */}

        <div style={styles.summary}>
          <h3 style={styles.summaryTitle}>Summary</h3>
          <div style={styles.summaryRow}>
            <span>Items</span>
            <span>${summary.subtotal.toFixed(2)}</span>
          </div>
          {appliedDiscount && (
            <div style={styles.summaryRow}>
              <span>Promotion ({appliedDiscount?.name || discountCode}):</span>
              <span>- ${summary.discountAmount.toFixed(2)}</span>
            </div>
          )}
          <div style={styles.summaryRow}>
            <span>Subtotal:</span>
            <span>${(summary.subtotal - summary.discountAmount).toFixed(2)}</span>
          </div>
          <div style={styles.summaryRow}>
            <span>Shipping:</span>
            <span>{summary.calculatedShippingCost == 0 ? "FREE" : `$${summary.calculatedShippingCost.toFixed(2)}`}</span>
          </div>
          <div style={styles.subtotalRow}>
            <span>Total:</span>
            <span style={styles.total}>${summary.total.toFixed(2)}</span>
          </div>

          <button
            style={{
              ...styles.checkoutButton,
              ...(cartItems.length === 0
                ? {
                    backgroundColor: "gray",
                    cursor: "not-allowed",
                    color: "rgba(255,255,255,.3)",
                  }
                : {}),
            }}
            onClick={handleCheckout}
            disabled={cartItems.length === 0}
          >
            Proceed to checkout
          </button>
          {cartItems.length === 0 && (
            <div
              style={{
                textAlign: "center",
                paddingTop: 20,
                fontSize: 12,
                color: "gray",
              }}
            >
              Your cart is empty
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  cartContainer: {
    backgroundColor: "#fff",
    padding: "20px 20px",
  },
  cartTitle: {
    fontSize: "12px",
    color: "gray",
    paddingTop: "20px",
    fontWeight: 100,
    paddingBottom: "10px",
  },
  cartItem: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    marginBottom: "5px",
  },
  productImage: {
    width: "50px",
    height: "50px",
    margin: "0px 10px",
  },
  productDetails: {
    flex: 1,
  },
  productTitle: {
    fontSize: "14px",
    marginBottom: "5px",
    fontWeight: 200,
  },
  productInfo: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  deleteButton: {
    fontSize: "12px",
    color: "blue",
    cursor: "pointer",
    background: "none",
    border: "none",
    padding: 0,
  },
  quantityControls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderLeft: "1px solid #ddd",
    height: "80px",
  },
  quantityButton: {
    width: "40px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "20px",
  },
  summary: {
    marginTop: "10px",
    padding: "10px 0",
    borderTop: "8px solid #ececeb",
  },
  summaryTitle: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    fontSize: "14px",
    padding: "3px 0",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    fontWeight: "bold",
  },
  total: {
    color: "#1fd400",
  },
  checkoutButton: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "15px 10px",
    marginTop: "20px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
  },
  discountContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  discountButton: {
    marginLeft: "10px",
    padding: "5px 15px",
    height: 39,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "white",
  },
};

export default Cart;
