import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Header from "../../elements/Header";
import { FaEdit, FaTrashAlt, FaStar } from "react-icons/fa";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { UserContext } from "../../context/UserContext";
import { EnvContext } from "../../context/EnvContext";
import axios from "axios";
import Footer from "../../elements/Footer";

const UserAddresses = () => {
  //
  const environment = useContext(EnvContext);
  //
  const db = useFirestore();
  const [isEditing, setIsEditing] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [address_name, setAddressName] = useState("");
  const [address, setAddress] = useState(null); // Update to hold the selected place object
  const [address_optional, setAddressOptional] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [currentAddressIndex, setCurrentAddressIndex] = useState(null);
  const [zipCode, setZipCode] = useState("");

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      console.log("User is not defined yet.");
      return;
    }

    const userRef = doc(db, `Users/UsersList/DataBase`, user?.uid);

    const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setAddresses(data.addresses || []);
      } else {
        console.error("No document found!");
      }
    });

    return () => unsubscribe();
  }, [db, user]);

  // Cancel editing or adding new and go back to the initial state
  const handleCancelEditing = () => {
    setIsEditing(false);
    setShowEditForm(false);
    setShowAddNewForm(false);
    setCurrentAddressIndex(null);
    setAddressName("");
    setAddress(null); // Reset the selected address
    setAddressOptional("");
    setZipCode("");
  };

  // Handle showing the editing mode with icons
  const handleEditClick = () => {
    setIsEditing(true);
    setShowAddNewForm(false); // Ensure add new form is not visible when editing
  };

  // Handle showing the edit form
  const handleEditFormClick = (index) => {
    setCurrentAddressIndex(index); // Track which address is being edited
    const addr = addresses[index];

    setAddressName(addr.address_name);
    setAddress({ label: addr.address, value: { place_id: addr.place_id } });
    setAddressOptional(addr.address_optional);
    setZipCode(addr.postalCode || "");
    setShowEditForm(true);
    setShowAddNewForm(false); // Ensure add new form is not visible when showing edit form
  };

  // Handle showing the add new form
  const handleAddNewClick = () => {
    setShowAddNewForm(true);
    setIsEditing(false); // Ensure editing mode is off when adding new
    setShowEditForm(false); // Ensure edit form is hidden when adding new
    setAddressName(""); // Reset form fields for adding new address
    setAddress(null);
    setAddressOptional("");
    setZipCode("");
  };

  // Handle form submission for both edit and add new forms
  const handleSaveChanges = async () => {
    if (!user) {
      console.error("User is not defined.");
      return;
    }

    const userRef = doc(db, `Users/UsersList/DataBase`, user?.uid);
    const updatedAddresses = [...addresses];

    const newAddressData = {
      address_name: address_name || "",
      address: address?.label || "", // Formatted address from autocomplete
      address_optional: address_optional || "",
      place_id: address?.value?.place_id || "", // Place ID from Google API
      postalCode: zipCode || "",
      street_name: "", // To be filled by the backend
      street_number: "", // To be filled by the backend
      city: "", // To be filled by the backend
      state: "", // To be filled by the backend
      country: "", // To be filled by the backend
    };

    if (showAddNewForm) {
      updatedAddresses.push(newAddressData);
    } else if (currentAddressIndex !== null) {
      updatedAddresses[currentAddressIndex] = newAddressData;
    }

    try {
      // Update Firestore with basic address data
      await updateDoc(userRef, { addresses: updatedAddresses });
      handleCancelEditing(); // Reset states after saving
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  // Handle deleting an address
  const handleDeleteAddress = async (index) => {
    if (!window.confirm("Are you sure you want to delete this address?")) return;

    const userRef = doc(db, `Users/UsersList/DataBase`, user?.uid);
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);

    await updateDoc(userRef, { addresses: updatedAddresses });

    setIsEditing(false);
  };

  // Handle setting a default address
  const handleSetDefaultAddress = async (index) => {
    const userRef = doc(db, `Users/UsersList/DataBase`, user?.uid);
    const updatedAddresses = [...addresses];
    const defaultAddress = updatedAddresses.splice(index, 1)[0];
    updatedAddresses.unshift(defaultAddress);

    await updateDoc(userRef, { addresses: updatedAddresses });
  };

  return (
    <div style={styles.container}>
      <Header />
      <div style={styles.content}>
        {!isEditing && !showEditForm && !showAddNewForm && (
          <>
            {addresses.map((element, index) => {
              let addressesTitle = null;
              if (index === 0) addressesTitle = <h4 style={styles.headerText}>Default address</h4>;
              if (index === 1) addressesTitle = <h4 style={styles.headerText}>Other addresses</h4>;

              return (
                <div key={index}>
                  {addressesTitle}
                  <div style={styles.addressBox}>
                    <h4 style={styles.nameText}>{element.address_name || "No Address"}</h4>
                    <p style={styles.addressText}>{element.address || ""}</p>
                    <p style={styles.addressText}>{element.postalCode || ""}</p>
                    <p style={styles.addressText}>{element.address_optional || ""}</p>
                  </div>
                </div>
              );
            })}
            <div style={styles.actionContainer}>
              {addresses.length > 0 && (
                <button style={styles.linkButton} onClick={handleEditClick}>
                  Edit addresses or default
                </button>
              )}
              <button style={styles.linkButton} onClick={handleAddNewClick}>
                Add new
              </button>
            </div>
          </>
        )}

        {isEditing && !showEditForm && (
          <>
            {addresses.map((addr, index) => (
              <div key={index} style={styles.addressBox}>
                <h4 style={styles.nameText}>{addr.address_name}</h4>
                <p style={styles.addressText}>{addr.address}</p>
                <p style={styles.addressText}>{addr.address_optional}</p>
                <div style={styles.actionButtons}>
                  <button style={styles.actionButton} onClick={() => handleEditFormClick(index)}>
                    <FaEdit size={12} style={styles.icon} /> Edit
                  </button>
                  <button style={styles.actionButton} onClick={() => handleDeleteAddress(index)}>
                    <FaTrashAlt size={12} style={styles.icon} /> Remove
                  </button>
                  <button style={styles.actionButton} onClick={() => handleSetDefaultAddress(index)}>
                    <FaStar size={12} style={styles.icon} /> Default address
                  </button>
                </div>
              </div>
            ))}
            <button style={styles.linkButton} onClick={handleCancelEditing}>
              Cancel editing
            </button>
          </>
        )}

        {(showEditForm || showAddNewForm) && (
          <div style={styles.editForm}>
            <label style={styles.label}>Please enter the recipient’s name</label>
            <input
              type="text"
              value={address_name}
              onChange={(e) => setAddressName(e.target.value)}
              style={styles.input}
              placeholder="Your Name (First Last)"
            />

            <label style={styles.label}>Please enter a delivery address</label>
            <div style={styles.autocompleteContainer}>
              <GooglePlacesAutocomplete
                apiKey="YOUR_GOOGLE_MAPS_API_KEY"
                selectProps={{
                  value: address,
                  onChange: setAddress,
                  placeholder: "Search for an address",
                  styles: {
                    control: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                      padding: "0px",
                      margin: "0px",
                      boxSizing: "border-box",
                      minHeight: "40px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                      padding: "0px 0px",
                      margin: "0px",
                      boxSizing: "border-box",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0px 10px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                      margin: "0px",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    // Remove the indicator separator (line next to the arrow)
                    indicatorSeparator: (provided) => ({
                      display: "none",
                    }),
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["us", "be"], // Optional: Restrict search to US addresses
                  },
                  fields: ["address_components", "geometry", "formatted_address"],
                }}
                style={{ border: "1px solid #ddd", marginBottom: "15px" }}
              />
            </div>

            <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} style={styles.input} placeholder="ZIP Code (Optional)" />

            <input
              type="text"
              value={address_optional}
              onChange={(e) => setAddressOptional(e.target.value)}
              style={styles.input}
              placeholder="Apt or building number (optional)"
            />

            <button style={styles.saveButton} onClick={handleSaveChanges}>
              Save address
            </button>
            <button style={styles.cancelButton} onClick={handleCancelEditing}>
              Cancel
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#fff",
  },
  headerText: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  addressBox: {
    width: "100%",
    border: "1px solid #ddd",
    padding: "15px",
    textAlign: "left",
    marginBottom: "20px",
  },
  nameText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  addressText: {
    fontSize: "14px",
    margin: "2px 0",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  linkButton: {
    fontSize: "14px",
    color: "blue",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginBottom: "10px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  actionButton: {
    fontSize: "12px",
    color: "gray",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: "10px",
  },
  icon: {
    marginRight: "5px",
  },
  editForm: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  input: {
    fontSize: "14px",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ddd",
  },
  autocompleteContainer: {
    marginBottom: "15px",
  },
  saveButton: {
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    marginTop: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  cancelButton: {
    fontSize: "14px",
    color: "black",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginTop: "15px",
  },
};

export default UserAddresses;
