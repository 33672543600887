import React, { useState, useEffect, useRef } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { doc, updateDoc } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext";
import { useNavigate } from "react-router-dom";
// auth

const AddressCheckout = ({ addresses, setAddresses, selectedAddressIndex, setSelectedAddressIndex, user }) => {
  const firestore = useFirestore();

  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [addressName, setAddressName] = useState("");
  const [addressAutocomplete, setAddressAutocomplete] = useState(null);
  const [addressOptional, setAddressOptional] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [zipCode, setZipCode] = useState("");

  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const isValid = addressName && addressAutocomplete;
    setIsSaveButtonDisabled(!isValid);
  }, [addressName, addressAutocomplete]);

  useEffect(() => {
    if (emailAddress && confirmEmailAddress && emailAddress !== confirmEmailAddress) {
      setEmailError("Emails do not match");
    } else {
      setEmailError("");
    }
  }, [emailAddress, confirmEmailAddress]);

  const toggleAddressOptions = () => {
    if (addresses?.length <= 1) {
      setShowAddNewForm(true);
    } else {
      setShowAddressOptions(!showAddressOptions);
      setShowAddNewForm(false);
    }
  };

  const handleAddressSelect = (index) => {
    setSelectedAddressIndex(index);
    setShowAddressOptions(false);
  };

  const handleAddNewAddress = () => {
    setShowAddNewForm(true);
    setShowAddressOptions(false);
  };

  const handleSaveNewAddress = async () => {
    if (!user?.uid) {
      console.error("User not authenticated. Please try to create account or contact support.");
      return;
    }

    const formattedAddress = addressAutocomplete?.label || "";
    const newAddress = {
      address_name: addressName,
      address: formattedAddress,
      address_optional: addressOptional || "",
      email: user?.email || emailAddress,
      place_id: addressAutocomplete?.value?.place_id || "",
      postalCode: zipCode || "",
      // To be filled by the backend
      street_name: "",
      street_number: "",
      city: "",
      state: "",
      country: "",
    };

    const updatedAddresses = [...addresses, newAddress];
    const userRef = doc(firestore, `Users/UsersList/DataBase`, user.uid);

    try {
      // Save updated addresses to Firestore
      await updateDoc(userRef, { addresses: updatedAddresses });
      setAddresses(updatedAddresses);
      setSelectedAddressIndex(updatedAddresses.length - 1);
      resetForm();
    } catch (error) {
      console.error("Error saving new address:", error);
    }
  };

  const resetForm = () => {
    setShowAddNewForm(false);
    setAddressName("");
    setAddressAutocomplete(null);
    setAddressOptional("");
    setEmailAddress("");
    setConfirmEmailAddress("");
    setZipCode("");
  };

  return (
    <div style={styles.address}>
      <h4 style={styles.headerText}>Delivery to</h4>
      {addresses?.length > 0 ? (
        <>
          <div style={styles.addressBox}>
            <h4 style={styles.nameText}>{addresses[selectedAddressIndex]?.address_name}</h4>
            <p style={styles.addressText}>{addresses[selectedAddressIndex]?.address}</p>
            <p style={styles.addressText}>{addresses[selectedAddressIndex]?.postalCode || ""}</p>
            <p style={styles.addressText}>{addresses[selectedAddressIndex]?.address_optional}</p>
          </div>
          <button style={styles.linkButton} onClick={toggleAddressOptions}>
            Use a different address
          </button>
        </>
      ) : (
        <button style={styles.linkButton} onClick={toggleAddressOptions}>
          Please add an address.
        </button>
      )}

      {showAddressOptions && addresses.length > 1 && (
        <div style={styles.addressOptions}>
          {addresses.map((addr, index) =>
            index !== selectedAddressIndex ? (
              <div key={index} style={styles.addressBox} onClick={() => handleAddressSelect(index)}>
                <h4 style={styles.nameText}>{addr.address_name}</h4>
                <p style={styles.addressText}>{addr.address}</p>
                <p style={styles.addressText}>{addr.postalCode || ""}</p>
                <p style={styles.addressText}>{addr.address_optional}</p>
                <p style={styles.changeAddressButton}>Use this address</p>
              </div>
            ) : null
          )}
          <button style={styles.linkButton} onClick={handleAddNewAddress}>
            Add new address
          </button>
        </div>
      )}

      {showAddNewForm && (
        <div style={styles.editForm}>
          {!user?.email && (
            <>
              <label style={styles.label}>Recipient’s Email</label>
              <input type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} style={styles.input} placeholder="Email address" />
              <label style={styles.label}>Confirm Email</label>
              <input
                type="text"
                value={confirmEmailAddress}
                onChange={(e) => setConfirmEmailAddress(e.target.value)}
                style={styles.input}
                placeholder="Confirm email"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </>
          )}

          <label style={styles.label}>Recipient’s Name</label>
          <input type="text" value={addressName} onChange={(e) => setAddressName(e.target.value)} style={styles.input} placeholder="Your Name" />

          <label style={styles.label}>Delivery Address</label>
          <GooglePlacesAutocomplete
            apiKey="YOUR_GOOGLE_MAPS_API_KEY"
            selectProps={{
              value: addressAutocomplete,
              onChange: setAddressAutocomplete,
              placeholder: "Search for an address",
              onMenuOpen: () => setDropdownOpen(true),
              onMenuClose: () => setDropdownOpen(false),
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ["us", "be"], // Optional: Restrict search to US addresses
              },
              fields: ["address_components", "geometry", "formatted_address"],
            }}
            style={{ border: "1px solid #ddd", marginBottom: "15px" }}
          />

          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            style={{ ...styles.input, marginTop: "10px" }}
            placeholder="ZIP Code (Optional)"
          />

          <input
            type="text"
            value={addressOptional}
            onChange={(e) => setAddressOptional(e.target.value)}
            style={{ ...styles.input }}
            placeholder="Apt or building number (optional)"
          />

          <button
            style={{
              ...styles.saveButton,
              ...(isSaveButtonDisabled && {
                backgroundColor: "gray",
                cursor: "not-allowed",
                opacity: 0.5,
              }),
            }}
            onClick={handleSaveNewAddress}
            disabled={isSaveButtonDisabled}
          >
            Save Address
          </button>
          <button style={styles.cancelButton} onClick={resetForm}>
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  address: { marginBottom: "10px" },
  headerText: { fontSize: "14px", color: "gray", marginBottom: "10px" },
  addressBox: { border: "1px solid #ddd", padding: "15px", marginBottom: "10px" },
  nameText: { fontSize: "16px", fontWeight: "bold" },
  addressText: { fontSize: "14px", margin: "2px 0" },
  linkButton: {
    fontSize: "14px",
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginBottom: "20px",
  },
  editForm: { display: "flex", flexDirection: "column" },
  label: { fontSize: "14px", color: "gray", marginBottom: "5px" },
  input: { fontSize: "14px", padding: "10px", border: "1px solid #ddd", marginBottom: "10px" },
  changeAddressButton: {
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  saveButton: {
    backgroundColor: "black",
    color: "white",
    height: "50px",
    marginTop: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  cancelButton: {
    fontSize: "14px",
    color: "black",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginTop: "15px",
  },
};

export default AddressCheckout;
