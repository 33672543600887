const config = {
  apiConfig: {
    //API_ENDPOINT_URL: "http://127.0.0.1:5001/sooperstock-app/us-central1/api",
    API_ENDPOINT_URL: "https://us-central1-sooperstock-app.cloudfunctions.net/api",
  },

  // enveironment: "staging" | "production"
  enveironment: {
    production: {
      collectionPath: "",
    },
    staging: {
      collectionPath: "_DEVELOPMENT/stageapi/",
      //collectionPath: "",
    },
  },
  //
  categories: [
    {
      label: "🥂",
      labelImage: "/images/filter/Baverage.png",
      value: "Beverage",
      title: "Beverages",
      product_category: ["Beverage", "Beverages"],
    },
    { label: "🍫", labelImage: "/images/filter/Edibles.png", value: "Edibles", title: "Edibles", product_category: ["Edibles", "Gummy", "Chocolate"] },
    { label: "👌", labelImage: "/images/filter/SelfCare.png", value: "self-care", title: "Self Care", product_category: ["Other", "Self Care"] },
    { label: "🌸", labelImage: "/images/filter/Flower.png", value: "Flower", title: "Flower", product_category: ["Flower"] },
    { label: "👌", labelImage: "/images/filter/Preroll.png", value: "Preroll", title: "Prerolls", product_category: ["Preroll"] },
    { label: "💨", labelImage: "/images/filter/Vape.png", value: "Vape", title: "Vape", product_category: ["Dispo", "Carts", "Pods", "Battery"] },
    { label: "💰", labelImage: "/images/filter/Deals.png", title: "Deals", value: "Deals" },
  ],
  types: ["Plant", "Magic"],
  strain_types: ["Indica", "Sativa", "Hybrid", "I-Hybrid", "S-Hybrid", "Other"],
};

export const categories = config.categories;
export const strain_types = config.strain_types;
export default config;
