import React, { useEffect, useState, useContext } from "react";
import AppRouter from "./navigation/AppRouter";
import { UserContextProvider } from "./context/UserContext";
import { SearchProvider } from "./context/SearchContext";
import { FirestoreContextProvider } from "./firebase/FirestoreContext";
import Cookies from "js-cookie";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import "./styles/global.css";
import { EnvContext } from "./context/EnvContext";
import { borderColor, borderRadius, textTransform } from "@mui/system";

const SooperSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.2)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "silver",
  },
}));

const defaultCookieSettings = {
  ads: true,
  tech: true,
  functional: true,
  marketing: true,
  statistical: true,
};

const App = () => {
  // environment
  const environment = useContext(EnvContext);

  //
  const [user, setUser] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [cookieSettings, setCookieSettings] = useState(defaultCookieSettings);
  const [displayCookieDetails, setDisplayCookieDetails] = useState(false);

  // Password protection
  const [passwordGate, setPasswordGate] = useState(environment === "staging" ? false : true);
  const [passwordInput, setPasswordInput] = useState("");
  const [showAgeGate, setShowAgeGate] = useState(false);
  const [showAgeRestrictionMessage, setShowAgeRestrictionMessage] = useState(false);

  const correctPassword = "3322";

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === correctPassword) {
      setPasswordGate(true);
      Cookies.set("passwordGate", true, { expires: 1 });
    } else {
      alert("Incorrect password!");
    }
  };

  useEffect(() => {
    // chek if user invite ?ui={inviteCode} is present in url
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCode = urlParams.get("ui");
    if (inviteCode) {
      // store invite code in cookie if cookie is not present
      if (!Cookies.get("userInviteCode")) {
        Cookies.set("userInviteCode", inviteCode, { expires: 365 });
      }
    }

    const passwordGateCookie = Cookies.get("passwordGate");
    if (passwordGateCookie == "true") {
      setPasswordGate(true);
    }

    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && currentUser.uid) {
        const userDocRef = doc(firestore, `Users/UsersList/DataBase`, currentUser.uid);
        try {
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = await userDocSnap.data();
            await setUserSettings(userData);
            await setupUserFirestoreListener(currentUser.uid, firestore);

            // set Invite code
            if (!userData.userInviteCode && Cookies.get("userInviteCode")) {
              // update Wallet with invite code
              let amount = 0;
              // updateWalletWithInviteCode(currentUser.uid, amount, Cookies.get("userInviteCode"));
            }
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      }
      setIsLoading(false);
    });

    return () => unsubscribeAuth();
  }, []);

  const setupUserFirestoreListener = async (uid, firestore) => {
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", uid);
    onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        setUserSettings(doc.data());
      }
    });
  };

  // Age gate logic
  useEffect(() => {
    if (Cookies.get("ageGate") !== "true") {
      setShowAgeGate(true);
    }
  }, []);

  const handleAcceptAgeGate = async () => {
    Cookies.set("ageGate", "true", { expires: 365 });
    setShowAgeGate(false);
  };

  const handleDeclineAgeGate = () => {
    setShowAgeRestrictionMessage(true);
    setShowAgeGate(false);
  };

  const renderAgeGateModal = () => {
    if (!showAgeGate) return null;

    return (
      <div style={styles.modal}>
        <div style={styles.modalContent}>
          {!showAgeRestrictionMessage ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
              <div style={{ backgroundColor: "#ff0000", padding: "20px 30px", borderRadius: "10px" }}>
                <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "auto", maxHeight: 55, marginBottom: 30 }} />
                <div style={{ color: "white", fontSize: 26, fontWeight: "800", marginBottom: 30 }}>
                  Welcome!
                  <br />
                  You must be 21+ to enter this site
                </div>
                <div style={{ ...styles.modalButtons }}>
                  <div
                    onClick={handleAcceptAgeGate}
                    style={{
                      ...styles.modalButton,
                      background: "white",
                      color: "black",
                      marginLeft: 5,
                      width: "80%",
                    }}
                  >
                    Enter
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ padding: "20px" }}>
              <span>Sorry, you must be 21 or older to access this site.</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Handle cookie banner logic
  useEffect(() => {
    const cookieResponded = Cookies.get("cookieResponded");
    if (cookieResponded) {
      setShowCookieBanner(false);
    }
  });

  const handleCookieChange = (field, value) => {
    setCookieSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
  };

  const handleAcceptCookies = async () => {
    Cookies.set("cookieResponded", true, { expires: 365 });
    setShowCookieBanner(false);
  };

  const handleDeclineCookies = async () => {
    Cookies.set("cookieResponded", true, { expires: 365 });
    setShowCookieBanner(false);
  };

  const renderCookiesBanner = () => {
    if (!showCookieBanner) return null;

    return (
      <div style={styles.cookie.container}>
        <div
          style={{
            ...styles.cookie.content,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {displayCookieDetails === false && (
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                By clicking 'Accept All' you consent that we may collect information about you for various purposes, including: Functionality, Analytics and
                Marketing
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <div onClick={() => setDisplayCookieDetails(true)} style={{ cursor: "pointer", color: "blue" }}>
                  Customize settings
                </div>
              </div>
            </div>
          )}
          {displayCookieDetails === true && (
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: 20, flex: 1, width: "100%" }}>
                <div
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      marginBottom: 16,
                      width: "100%",
                    }}
                  >
                    YOU CONTROL YOUR DATA
                  </h2>
                  <div
                    onClick={() => setDisplayCookieDetails(false)}
                    style={{
                      cursor: "pointer",
                      color: "black",
                      float: "right",
                    }}
                  >
                    x
                  </div>
                </div>
                <p style={{ fontSize: ".9rem", marginBottom: 12 }}>
                  We and our partners use technologies, including cookies, to collect information about you for various purposes, including:
                </p>
                <ol
                  style={{
                    fontSize: ".9rem",
                    paddingLeft: 20,
                    marginBottom: 16,
                  }}
                >
                  <li>Functionality</li>
                  <li>Analytics</li>
                  <li>Marketing</li>
                </ol>
                <p style={{ fontSize: ".9rem", marginBottom: 20 }}>
                  By clicking 'Accept All' you consent to all these purposes. You can also choose to indicate what purposes you will consent to using the custom
                  selections and then click 'Save my choices'.
                </p>
              </div>

              <div style={styles.cookie.options}>
                <div style={styles.cookie.options.container}>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Necessary</span>
                    <SooperSwitch disabled color="default" checked={cookieSettings.tech} onChange={(e) => handleCookieChange("tech", e.target.checked)} />
                  </div>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Functional</span>
                    <SooperSwitch color="default" checked={cookieSettings.functional} onChange={(e) => handleCookieChange("functional", e.target.checked)} />
                  </div>
                </div>
                <div style={styles.cookie.options.container}>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Marketing</span>
                    <SooperSwitch color="default" checked={cookieSettings.marketing} onChange={(e) => handleCookieChange("marketing", e.target.checked)} />
                  </div>
                  <div style={styles.cookie.options.content}>
                    <span style={{ fontSize: ".9rem" }}>Analytics</span>
                    <SooperSwitch color="default" checked={cookieSettings.statistical} onChange={(e) => handleCookieChange("statistical", e.target.checked)} />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={styles.cookie.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCookieSettings({
                  tech: cookieSettings.tech,
                  functional: cookieSettings.functional,
                  marketing: cookieSettings.marketing,
                  statistical: cookieSettings.statistical,
                });
                handleAcceptCookies();
              }}
              style={{
                marginRight: 16,
                width: "100%",
                background: "black",
                color: "white",
                marginBottom: 10,
              }}
            >
              {displayCookieDetails === true ? "ACCEPT SELECTED" : "ACCEPT ALL"}
            </Button>
            <Button
              variant="outlined"
              color=""
              style={{
                marginRight: 16,
                width: "100%",
                background: "black",
                color: "white",
              }}
              onClick={handleDeclineCookies}
            >
              DECLINE ALL
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (passwordGate !== true) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            placeholder="Enter password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            style={{ height: 50, marginRight: 10 }}
          />
          <Button
            type="submit"
            variant="contained"
            color=""
            style={{
              background: "#ff0000",
              color: "white",
              height: 50,
              marginTop: -3,
            }}
          >
            Submit
          </Button>
        </form>
      </div>
    );
  }

  return (
    <FirestoreContextProvider>
      <UserContextProvider>
        <SearchProvider>
          <AppRouter />
          {renderCookiesBanner()}
          {renderAgeGateModal()}
        </SearchProvider>
      </UserContextProvider>
    </FirestoreContextProvider>
  );
};

const styles = {
  modal: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    textAlign: "center",
  },
  modalButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  modalButton: {
    border: "3px solid white",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "50px",
    cursor: "pointer",
  },
  cookie: {
    container: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      zIndex: 1000,
      height: "100vh",
      padding: "10px",
      alignContent: "flex-end",
    },
    content: {
      backgroundColor: "white",
      borderRadius: "10px",
      width: "100%",
      color: "black",
      padding: "20px",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },
};

export default App;
