import React, { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig"; // Import db directly
import { EnvContext } from "./EnvContext";
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import config from "../config";

// Create the UserContext
export const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
  //
  const environment = useContext(EnvContext);
  //
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    let unsubscribeFirestore = null;

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && currentUser.uid) {
        //
        const userRef = doc(db, `Users/UsersList/DataBase`, currentUser.uid);

        // Get user data from Firestore
        unsubscribeFirestore = onSnapshot(userRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUser({ uid: currentUser.uid, ...userData, ...currentUser });
          } else {
            console.error("User document does not exist!");
          }
          setLoading(false); // Set loading to false once user data is fetched
        });
      } else {
        setUser(null);
        if (unsubscribeFirestore) {
          unsubscribeFirestore();
          unsubscribeFirestore = null;
        }
        setLoading(false); // Set loading to false if no user is logged in
      }
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribeAuth();
      if (unsubscribeFirestore) {
        unsubscribeFirestore();
      }
    };
  }, []);

  // cart items
  // Fetch cart data on user login
  useEffect(() => {
    let unsubscribeCart = null;

    const fetchCart = async () => {
      try {
        if (user) {
          // Query the Carts collection for the active cart
          const cartQuery = query(
            collection(db, `${collectionPath}Carts/CartsList/DataBase`),
            where("user_id", "==", user.uid),
            where("status", "==", "ordering")
          );

          const cartSnapshot = await getDocs(cartQuery);

          if (!cartSnapshot.empty) {
            const cartDoc = cartSnapshot.docs[0]; // Assuming one active cart per user
            const cartId = cartDoc.id;

            // Set up a listener for real-time updates in the Items subcollection
            const itemsRef = collection(db, `${collectionPath}Carts/CartsList/DataBase/${cartId}/Items`);

            unsubscribeCart = onSnapshot(itemsRef, (snapshot) => {
              const cartItems = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              setCart(cartItems); // Update the cart state dynamically
            });
          } else {
            setCart([]); // No active cart found
          }
        } else {
          setCart([]); // User not logged in
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };

    fetchCart();

    return () => {
      if (unsubscribeCart) unsubscribeCart(); // Clean up the listener on unmount
    };
  }, [user, collectionPath]);

  return <UserContext.Provider value={{ user, cart, loading }}>{children}</UserContext.Provider>;
};
