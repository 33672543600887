import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { collection, getDocs } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../context/EnvContext";
import config from "../config";

const FeaturedCarousel = ({ featuredCollection }) => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideInterval = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const isAutoScrolling = useRef(false); // Flag to distinguish auto-scroll from user interaction
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasContent, setHasContent] = useState(true);

  // Feature products
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        setLoading(true);
        const featuredCollectionRef = collection(db, `${collectionPath}Listings/FeaturedList/DataBase`);
        const featuredSnapshot = await getDocs(featuredCollectionRef);

        const featuredData = featuredSnapshot.docs
          .map((doc) => {
            const productData = doc.data();
            return {
              id: doc.id,
              featureCollection_name: productData.featureCollection_name || "N/A",
              sorting: productData.sorting || 0,
              ...productData,
            };
          })
          .filter((product) => product.featureCollection_name === featuredCollection && product.enabled === true)

          .sort((a, b) => a.sorting - b.sorting);

        setFeaturedProducts(featuredData);
        setHasContent(featuredData.length > 0); // Check if there are items
      } catch (error) {
        console.error("Error fetching featured products:", error);
        setHasContent(false);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedProducts();
  }, [db, collectionPath, featuredCollection]);

  // Clone the list multiple times for smoother infinite scroll
  const repeatedProducts = featuredProducts.length > 1 ? Array(20).fill(featuredProducts).flat() : featuredProducts;

  // Start auto-slide
  const startAutoSlide = () => {
    if (featuredProducts.length <= 1) return; // No auto-slide if there's 0 or 1 product

    slideInterval.current = setInterval(() => {
      isAutoScrolling.current = true;
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 5000);
  };

  // Stop and restart auto-slide
  const resetAutoSlide = () => {
    if (slideInterval.current) clearInterval(slideInterval.current);
    startAutoSlide();
  };

  // Auto-slide on mount
  useEffect(() => {
    if (featuredProducts.length > 1) {
      startAutoSlide();
    }
    return () => clearInterval(slideInterval.current); // Cleanup
  }, [featuredProducts]);

  // Scroll to the current index
  useEffect(() => {
    if (featuredProducts.length === 0 || !containerRef.current || !containerRef.current.firstChild) {
      console.warn("No products or container is not ready. Skipping scrollTo.");
      return;
    }

    const cardWidth = containerRef.current.firstChild.offsetWidth || 0;

    containerRef.current.scrollTo({
      left: currentIndex * cardWidth,
      behavior: isAutoScrolling.current ? "smooth" : "instant",
    });

    const timer = setTimeout(() => {
      isAutoScrolling.current = false; // Reset auto-scroll flag
    }, 500);

    return () => clearTimeout(timer);
  }, [currentIndex, featuredProducts]);

  // Handle manual scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (isAutoScrolling.current || featuredProducts.length === 0) return;

      if (containerRef?.current) {
        const cardWidth = containerRef?.current?.firstChild?.offsetWidth || 0;
        const newIndex = Math.round(containerRef?.current?.scrollLeft / cardWidth);

        setCurrentIndex(newIndex); // Update index based on scroll position
        resetAutoSlide();
      }
    };

    const container = containerRef?.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => container?.removeEventListener("scroll", handleScroll);
  }, [featuredProducts]);

  // Reset to the middle of the repeated list
  useEffect(() => {
    if (featuredProducts.length === 0 || !containerRef.current || !containerRef.current.firstChild) {
      return;
    }

    const middleIndex = Math.floor(repeatedProducts.length / 2);
    if (currentIndex === 0 || currentIndex === repeatedProducts.length - 1) {
      setTimeout(() => {
        const cardWidth = containerRef.current.firstChild.offsetWidth || 0;
        containerRef.current.scrollTo({
          left: middleIndex * cardWidth,
          behavior: "instant",
        });
        setCurrentIndex(middleIndex);
      }, 500);
    }
  }, [currentIndex, repeatedProducts.length, featuredProducts]);

  const handleDotClick = (index) => {
    if (featuredProducts.length === 0) return;

    const middleIndex = Math.floor(repeatedProducts.length / 2);
    setCurrentIndex(middleIndex + index); // Adjust for middle of repeated list
    resetAutoSlide();
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <GridLoader />
      </div>
    );
  }

  if (!hasContent) {
    return <div style={{ textAlign: "center", marginTop: "20px" }}></div>;
  }

  return (
    <div style={{ display: window.innerWidth <= 9768 ? "block" : "none" }}>
      <div style={styles.title}></div>
      <div ref={containerRef} style={styles.carouselContainer}>
        {repeatedProducts.map((product, index) => (
          <div
            key={index}
            onClick={() => {
              if (product.featured_url.startsWith("http://") || product.featured_url.startsWith("https://")) {
                window.location.href = product.featured_url;
              } else {
                navigate(product.featured_url);
              }
            }}
            style={{
              ...styles.card,
              flex: featuredProducts.length === 1 ? "0 0 100%" : "0 0 90%",
            }}
          >
            <img src={product.image_url || "https://placehold.co/800x450"} alt={product.title} style={styles.image} />
            <div style={styles.loaderOverlay}>
              <GridLoader />
            </div>
          </div>
        ))}
      </div>

      {featuredProducts.length > 1 && (
        <div style={styles.dotsContainer}>
          {featuredProducts.map((_, index) => {
            const middleIndex = Math.floor(repeatedProducts.length / 2);
            const isActive = currentIndex % featuredProducts.length === index;

            return (
              <div
                key={index}
                onClick={() => handleDotClick(index)}
                style={{
                  ...styles.dot,
                  backgroundColor: isActive ? "red" : "#ccc",
                  opacity: isActive ? 1 : 0.5,
                }}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
}; // End of return

export default FeaturedCarousel;

const styles = {
  title: {
    padding: "15px 0px 0px 10px",
    fontSize: 12,
    fontWeight: 600,
  },
  carouselContainer: {
    display: "flex",
    overflowX: "auto",
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
    padding: "5px 10px 15px 10px",
    gap: "10px",
    maxWidth: "100%",
    scrollbarWidth: "none",
  },
  card: {
    scrollSnapAlign: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: "12px",
    maxHeight: "190px",
    maxWidth: "340px",
    border: "1px solid #e0e0e0",
    background: "#fafafa",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    aspectRatio: "800/450",
    zIndex: 10,
    position: "relative",
  },
  loaderOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    zIndex: 0,
    opacity: 0.1,
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    paddingTop: "10px",
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    cursor: "pointer",
  },
};
