import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { EnvContext } from "../../context/EnvContext";
import Footer from "../../elements/Footer";

const Help = ({ isDevMode }) => {
  //
  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header title="FAQ" />
      <div style={{ padding: "20px", display: "flex", flexDirection: "column", flex: 1 }}>
        <div>
          <br />
          For help with account or order issues, please email us: <a href="mailto:support@sooperstock.com">support@sooperstock.com</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Help;
